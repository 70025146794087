import { SurveyComponent } from "../../survey_component";
import env from "../../../../env";

const required = env.ENDPOINT !== "local";

const slider = (title, name) =>
  new SurveyComponent({
    title,
    name,
    required,

    type: "nouislider",
    step: 5,
    rangeMin: 0,
    rangeMaxStart: 400,
    maxStep: 400,
    rangeMax: Infinity,
  });

export const ProjectBalanceSheetAccountsReceivable = new SurveyComponent({
  name: "panel_pbs_accounts_receivable",
  type: "panel",
  innerIndent: 1,
  title: {
    en: "Accounts Receivable",
    ar: "الذمم المدينة",
  },
  elements: [
    slider(
      {
        ar: "ذمم على زبائن المشروع لشهر",
        en: "Receivables to project customers for a month",
      },
      "project_balance_sheet_accounts_receivables_one_month"
    ),

    slider(
      {
        ar: "الذمم على زبائن المشروع لأكثر من شهر",
        en: "Receivables to project customers for more than a month",
      },
      "project_balance_sheet_accounts_receivables_more_months"
    ),

    slider(
      {
        ar: "ديون معدومة (لم يتم تحصيلها بعد)",
        en: "Write off debts (not yet collected)",
      },
      "project_balance_sheet_accounts_write_off_debts"
    ),

    {
      title: {
        en: "Total receivables",
        ar: "اجمالي الذمم المدينة",
      },
      type: "expression",
      name: "project_balance_sheet_total_receivables",
      expression:
        "fixed(add({project_balance_sheet_accounts_receivables_one_month}, subtract({project_balance_sheet_accounts_receivables_more_months}, {project_balance_sheet_accounts_write_off_debts})), 2)", // Floating Point Proof
      displayStyle: "currency",
      currency: "JOD",
    },
  ],
});
