import { SurveyComponent } from "../../survey_component";

export const CurrentJobSalarySlip = new SurveyComponent({
  title: {
    en: "Please attach your salary slip.",
    ar: "يرجى إرفاق قسيمة راتبك."
  },
  description: {
    en: "If you cannot find the document now, you can close this form and come back later.",
    ar: "إذا لم تتمكن من العثور على المستند الآن ، يمكنك إغلاق هذا النموذج والعودة اليه لاحقًا",
  },
  name: "current_job_salary_slip",
  type: "file_uploader",
  accept: "image/*,application/pdf",
  isRequired: false,
});
