import { SurveyComponent } from "../../survey_component";
import env from "../../../../env";

const required = env.ENDPOINT !== "local";

export const HomeAddressDetails = new SurveyComponent({
  name: "home_addres_details",
  type: "comment",
  isRequired: required,
  title: {
    en: "Address Details *",
    ar: "تفاصيل عنوان المنزل *"
  }
});
