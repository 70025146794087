import { SurveyComponent } from "../../survey_component";
import env from "../../../../env";

const required = env.ENDPOINT !== "local";

export const IdentificationQuestionBankname = new SurveyComponent({
  name: "id_question_bank_name",
  type: "text",
  isRequired: required,
  title: {
    en: "Bank Name *",
    ar: "اسم البنك *"
  }
});
