import { FamilyMaritalStatusDropdown } from "../../components/survey/questions/family";
import Config from "../../config";
import env from "../../env";
const required = env.ENDPOINT !== "local";

export const guarantor_demo ={
  showProgressBar: "top",
  showQuestionNumbers: "off",
  sendResultOnPageNext: true,
  firstPageIsStarted: false,
  showCompletedPage: false,
  completeText: {
    en: "Sign",
    "es-MX": "Firmar aplicacion",
  },
  questionStartIndex: 1,
  requiredText: "",
  pages: [
    // Guarantor acceptance
    {
      title: { en: "Guarantor Acceptance", ar: "موافقة الضامن" },
      elements: [
        {
          title: {
            en: `Welcome {first_name}, you are invited to be the guarantor of {user_first_name} for a loan amount
                  of {formatted_amount} Request at SPARK. To fulfil this invitation, you will be asked for some information
                  about yourself. Please select whether the invitation is accepted from your side or not. If you
                  have any questions, please contact us.`,
            ar: `مرحبًا (اسم الضامن) ، أنت مدعو لتكون الضامن لـ {first_name} للحصول على طلب قرض لمبلغ {amount} في SPARK.
                 لتلبية هذه الدعوة ، سيُطلب منك بعض المعلومات عن نفسك.
                الرجاء تحديد ما إذا كانت الدعوة ستقبل من جانبك أم لا. إذا كان لديك أي أسئلة، يرجى الاتصال بنا.`
          },
          name: "guarantor_refused",
          type: "radiogroup",
          isRequired: required,
          choices: [
            {
              value: false,
              text: { en: "I agree", ar: "موافق" },
            },
            {
              value: true,
              text: { en: "I don't accept", ar: "غير موافق" },
            },
          ],
        },
        {
          visibleIf: "1 == 0",
          name: "formatted_amount",
          type: "expression",
          expression: "{amount}",
          displayStyle: "currency",
          currency: "USD"
        }
      ]
    },
    // Guarantor intake
    {
      title: { en: "Guarantor Intake", ar: "إفادة الضامن" },
      elements: [
        {
          title: {
            en: "What is your first name? *",
            ar: "ما هو اسمك الأول؟"
          },
          type: "text",
          name: "guarantor_first_name",
          isRequired: required,
        },
        {
          title: {
            en: "What is your last name? *",
            ar: "ما هو اسم عائلتك؟"
          },
          type: "text",
          name: "guarantor_last_name",
          isRequired: required,
        },
        {
          title: {
            en: "What is your date of birth? *",
            ar: "ما هو تاريخ ميلادك؟"
          },
          type: "text",
          inputType: "date",
          name: "date_of_birth",
          isRequired: required,
        },
        {
          title: {
            en: "To verify your identity please upload a picture of yourself *",
            ar: "للتحقق من هويتك يرجى تحميل صورة لك"
          },
          type: "file_uploader",
          accept: "image/*,application/pdf",
          name: "picture_1",
          isRequired: required,
        },
        {
          title: { en: "Gender *", ar: "ما هو جنسك؟" },
          name: "gender",
          type: "dropdown",
          isRequired: required,
          choices: [
            { value: "male", text: { en: "Male", ar: "ذكر" } },
            { value: "female", text: { en: "Female", ar: "أنثى" } },
          ],
        },
        {
          title: {
            en: "What is your nationality? *",
            ar: "ما هي جنسيتك؟"
          },
          type: "dropdown",
          name: "nationality",
          renderAs: "select2",
          choices: Config.nationalities || [],
        },
        {
          title: {
            en: "What identification document do you have? *",
            ar: "ما هي الوثائق التعريفية التي تملكها؟"
          },
          type: "dropdown",
          name: "id_type",
          isRequired: required,
          choices: [
            {
              value: "civil_or_unified_national_card",
              text: {
                en: "Civil status ID or the Unified National Card",
                ar: "بطاقة الأحوال المدنية أو البطاقة الوطنية الموحدة"
              }
            },
            {
              value: "nationality_certificate",
              text: {
                en: "Nationality certificate",
                ar: "شهادة الجنسية"
              }
            },
            {
              value: "residence_card",
              text: {
                en: "Residence card",
                ar: "بطاقة الإقامة"
              }
            },
            {
              value: "ration_card",
              text: {
                en: "Ration card",
                ar: "البطاقة التموينية"
              }
            },
            {
              value: "passport",
              text: {
                en: "Passport",
                ar: "جواز سفر"
              }
            },
            {
              value: "biometric_voter_card",
              text: {
                en: "The Biometric Voter Card",
                ar: "بطاقة الناخب البيومترية"
              }
            }
          ]
        },
        {
          title: {
            en: "National ID / Document No. *",
            ar: "رقم الهوية الوطنية / رقم الوثيقة"
          },
          type: "text",
          name: "id_number",
          isRequired: required,
        },
        {
          title: {
            en: "Front image of your ID *",
            ar: "الصورة الأمامية لبطاقة هويتك"
          },
          type: "file_uploader",
          name: "id_file",
          accept: "image/*,application/pdf",
          isRequired: required,
        },
        {
          title: {
            en: "Back image of your ID *",
            ar: "الصورة الخلفية لبطاقة هويتك"
          },
          type: "file_uploader",
          name: "id_file_back",        
          accept: "image/*,application/pdf",
          isRequired: required,
        },
        {
          title: 
            {
              en: "To verify your identification, please upload a picture of yourself holding your ID *",
              ar: "من أجل التحقق من هويتك ، يرجى تحميل صورة لنفسك ممسكاً هويتك"
            },
          type: "file_uploader",
          name: "id_file_with_avatar",
          accept: "image/*,application/pdf",
          isRequired: required,
        },
        {
          title: {
            en: "What is the type of relationship you have with the applicant? (ie, friend, family, boss, collegue,...) *",
            ar: "ما نوع علاقتك بمقدم الطلب؟ (على سبيل المثال ، صديق ، عائلة ، مدير ، زميل ،"
          },
          type: "text",
          name: "custom_text_1",
          isRequired: required,
        },
        FamilyMaritalStatusDropdown,
        {
          title: {
            en: "Do you have any outstanding loans? *",
            ar: "هل لديك أي قروض مستحقة؟"
          },
          name: "custom_text_2",
          type: "dropdown",
          isRequired: required,
          choices: [
            { value: "yes", text: { en: "Yes", ar: "نعم" } },
            { value: "no", text: { en: "No", ar: "لا" } },
          ],
        },
        {
          title: {
            en: "Outstanding loans",
            ar: "القروض الغير مسددة"
          },
          visibleIf: "{custom_text_2} == 'yes'",
          name: "custom_jsonfield_1",
          valueName: "custom_jsonfield_1",
          type: "matrixdynamic",
          minRowCount: 1,
          maxRowCount: 5,
          rowLayout: "vertical",
          rowCount: 1,
          addRowLocation: "bottom",
          isRequired: required,
          addRowText: { en: "Add" },
          columns: [
            {
              title: {
                en: "Name of the Institution *",
                ar: "اسم المؤسسة"
              },
              cellType: "text",
              name: "institution",
              isRequired: required
            },
            {
              title: {
                en: "Amount *",
                ar: "المبلغ"
              },
              cellType: "text",
              name: "amount",
              isRequired: required,
              inputType: "number"
            }
          ]
        },
      ]
    }
  ]
}