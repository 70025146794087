import { SurveyComponent } from "../../../survey_component";
import env from "../../../../../env";

const required = env.ENDPOINT !== "local";

export const SecondGuarantorPhoneVerified = new SurveyComponent({
  name: "guarantor_2_phone_verified",
  type: "phone",
  visibleIf: "{guarantor_2_first_name} notempty",
  isRequired: required,
  title: {
    en: "Confirm Guarantor Mobile No. *",
    ar: "تأكيد رقم الهاتف للكفيل *",
  },
  validators: [
    {
      type: "expression",
      expression: "{guarantor_2_phone} == {guarantor_2_phone_verified}", // Floating Point Proof
      text: {
        en: "Please make sure both numbers are the same",
        ar: "يرجى التأكد من تطابق كلا الرقمين "
      }
    }
  ],
});
