const page_1 = {
  title: {
    en: "Terms and conditions",
    rw: "Amategeko n'amabwiriza",
  },
  name: "loan",
  elements: [
    {
      name: "insurance",
      type: "panel",
      innerIndent: 1,
      elements: [
        {
          type: "html",
          title: "",
          html: {
            en: `I, the undersigned, hereby declare the validity and accuracy of the above-mentioned information, data and
                  documents provided by me to MoneyPhone Africa Ltd., the Lender, and that they are identical to my personal
                  information and have been filled out by me personally with my full legal capacity, my freedom and my will.`,
            rw: `Njyewe, nemeje ko amakuru nahaye MoneyPhone Africa Ltd ko ari ukuri , kandi ko ntanze amakuru yanjye bwite
                  kandi yujujwe nanjye mu mudendezo no kubushake bwanjye  mu uburyo bwemewe n'amategeko.`,
          },
        },
        {
          type: "html",
          html: {
            en: `I have no objection to the Lender verifying all the data submitted by me or by any party that the Lender
                  seems appropriate.`,
            rw: `Nemeye kandi ndumva neza ko nta nzitizi mfite ko MoneyPhone yagenzura cg yabaza  yatanga amakuru kunguzanyo
                  yanjye ku kigo gishinzwe gukenzura inguzanyo nibindi bigo na bank nkuru y'igihugu, Nemeye ko MoneyPhone
                  yansaba andi makuru yose ajyanye n'ubusabe bw'inguzanyo yanjye igihe ari cyose iyakeneye.`,
          },
        },
        {
          type: "html",
          html: {
            en: `I, the undersigned, declare that I have no objection to Lender inquiring and/or viewing and/or providing
                  and/or exchanging credit information and/or other information about me with credit information companies,
                  and the exchange of any of the information provided by me and any other relevant data with the Central
                  Bank and/or any other related parties as of its date without the Lender bearing any legal responsibility
                  for that, and I acknowledge the right The Lender may request any additional information and/or documents
                  at any time, or review one of its branches if necessary. I also acknowledge that I made the inquiry and
                  that I am fully aware of all the administrative and legal aspects of failure to pay on time.`,
            rw: "Ndemera kandi  nzi neza ibijyanye n'amabwiriza n'amategeko byose bijyanye no kutishyurira ku gihe inguzanyo nsabye.",
          },
        },
        {
          type: "html",
          html: {
            en: `I also declare that I have inquired and that I am fully aware of all financing conditions, prices,
                  administrative expenses, terms and conditions of use.`,
            rw: `Ndemeza ko nabajije kandi nzi neza  ibiciro byose by'iyi nguzanyo, umufuragiro, inyungu k'unguzanyo
                  nandi mabwiriza yo gukoresha iyi nguzanyo`,
          },
        },
        {
          type: "html",
          html: {
            en: `I declare that all copies of the attached documents that have been attached to the website/electronic
                  application are true copies and that all edited data are written by me, correct and under my responsibility.`,
            rw: `Ndemeza ko kopi zose zinyandiko nometse kurubuga  rwa MoneyPhone ari kopi zukuri kandi ko amakuru yose
                  yahinduwe yanditswe nanjye kandi ari ukuri`,
          },
        },
        {
          type: "html",
          html: {
            en: `I acknowledge that I have read and agreed to the terms, conditions and privacy policy provided to me
                  by the Lender, MoneyPhone Africa Ltd.`,
            rw: "Nemeye ko nasomye kandi nemera amabwiriza y'iyinguzanyo MoneyPhone Africa Ltd impaye.",
          },
        },
        {
          title: "removeMe",
          type: "checkbox",
          name: "agreeMicroInsuranceProgramms",
          isRequired: true,
          choices: [
            {
              value:
                "Applicant has agreed to micro insurance programs in the review flow.",
              text: {
                en: "I agree",
                rw: "Ndabyemeye",
              },
            },
          ],
          validators: [],
        },
      ],
    },
  ],
}

const payment_methods = {
  name: "payment_methods",
  type: "panel",
  title: {
    en: "Payment Methods",
    rw: "Uburyo bwo kwishyura",
  },
  elements: [
    {
      type: "html",
      html: {
        en: `<p>
              You can pay the loan with one of the following methods:
            </p>
            <ul>
              <li>
                MTN wallet
                <ul>
                  <li>
                    Momo pay code: 987825
                  </li>
                </ul>
              </li>
              <li>
                I&M Bank Account
                <ul>
                  <li>
                    20046413005
                  </li>
                </ul>
              </li>
            </ul>`,
        rw: `<p>
              Ushobora kwishyura inguzanyo ushoresheje bumwe muburyo bukurikira:
            </p>
            <ul>
              <li>
                MTN wallet
                <ul>
                  <li>
                    Momo pay code: 987825
                  </li>
                </ul>
              </li>
              <li>
                I&M Bank Account
                <ul>
                  <li>
                    20046413005
                  </li>
                </ul>
              </li>
            </ul>`
      }
    }
  ]
}

export const sign_rwanda_direct_model_chili = {
  showProgressBar: "top",
  showQuestionNumbers: "off",
  sendResultOnPageNext: true,

  firstPageIsStarted: false,

  showCompletedPage: false,
  completeText: {
    en: "Submit loan application",
    rw: "Ohereza ubuse bw'inguzanyo",
  },

  questionStartIndex: 1,
  requiredText: "",

  pages: [
    // Page 1: Terms and conditions
    page_1,
    // Page 2: Loan Agreement
    {
      title: {
        en: "Loan Agreement",
        rw: "Amasezerano y'inguzanyo",
      },
      name: "review",
      innerIndent: 1,

      elements: [
        {
          type: "expression",
          name: "today_date",
          expression: "daysFromNow(0, 0, 'rw')",
          title: { en: "Date", rw: "Italiki" },
        },
        {
          name: "total_overview",
          type: "panel",
          visibleIf: "{amount} notempty and {repayment_period} notempty",
          elements: [
            {
              type: "html",
              html: {
                en: "Between:",
                rw: "Hagati : ",
              },
            },
            {
              type: "html",
              html: {
                en: `MoneyPhone Africa LTD, residing in Rwanda and represented by Jean Paul Mushumba, hereinafter
                      referred to as the "Lender,"`,
                rw: `MoneyPhone Africa LTD, ikigo cy'ubucuruzi gitanga inguzanyo kibarizwa mu Rwanda kikaba
                      gihagarariwe na Jean Paul Mushumba, aha akaba ariwe uguriza.`,
              },
            },
            {
              type: "html",
              html: {
                en: "and",
                rw: "na",
              },
            },
            {
              type: "html",
              html: {
                en: `{first_name} {last_name}, residing at {province}, {district}, {home_country}, {cell}, {village},
                      hereinafter referred to as the "Borrower".`,
                rw: `ugurizwa, {first_name} {last_name}, utuye {province}, {district}, {home_country}, {cell}, {village},
                      aha akaba ariwe ugurizwa.`,
              },
            },
            {
              type: "html",
              visibleIf: "{intake.custom_text_7} == 'yes'",
              html: {
                en: 'With bank account No. {bank_account}, bank name {bank_name}',
                rw: "Ufite Konti ya Banki Nomero {bank_account} ibarizwa muri banki {bank_name}",
              },
            },
            {
              type: "html",
              visibleIf: "{intake.custom_text_5} == 'yes'",
              html: {
                en: "With mobile wallet {mobile}, with phone number {phone_number}",
                rw: "Na Konti ya MOMO yanditswe kuri {mobile} ufite nomero ya tel {phone_number}",
              },
            },
            {
              type: "html",
              html: {
                en: "All parties agree to enter into the following loan agreement, subject to the following terms and conditions:",
                rw: "Impande zombi zemeranyijwe kugirana amasezerano yinguzanyo, hashingiwe ku ngingo zikurikira:",
              },
            },
          ],
        },
        {
          name: "total_overview",
          type: "panel",
          title: {
            en: "Loan Terms",
            rw: "Ingingo zigize aya masezerano y'inguzanyo",
          },
          description: {
            en: "The borrower is committing to pay the lender the amount borrowed in accordance with the following:",
            rw: "Ugurizwa yiyemeje kwishyura uwamugurije umwenda akurikije ibi bikurikira:",
          },
          visibleIf: "{amount} notempty and {repayment_period} notempty",
          elements: [
            {
              name: "loan_terms",
              valueName: "loan_terms",
              type: "expression",
              expression:
                "loan_terms", // Floating Point Proof
              title: "Technical loan terms (NOT visible for user)",
              visibleIf: "{amount} < 0",
            },
            {
              title: {
                en: "Requested loan amount in RWF",
                rw: "Amafaranga y'inguzanyo asabwa",
              },
              type: "expression",
              expression: "{requested_amount}",
            },
            {
              title: {
                en: "Approved loan amount in RWF",
                rw: "Amafaranga y'inguzanyo yemejwe",
              },
              type: "expression",
              expression: "{amount}", // Floating Point Proof
            },
            {
              title: {
                en: "Loan term (in months)",
                rw: "Igihe inguzanyo izamara (Mu mezi)",
              },
              name: "repayment_period",
              type: "expression",
              expression: "{repayment_period}", // Floating Point Proof
            },
            {
              title: {
                en: "Total administrative fees in RWF to be deducted from the loan amount ({admin_fee})",
                rw: "Amafaranga ya serivise y'ubuyobozi angana na {admin_fee} akurwa kunguzanyo yemejwe",
              },
              type: "expression",
              expression: "{charge_dict.charge_code_1.total}", // Floating Point Proof
            },
            {
              title: {
                en: "Total VAT on administrative fees in RWF to be deducted from the loan amount ({vat_on_admin_fee})",
                rw: "Umusoro(VAT) ukurwa kunguzanyo yemejwe ({vat_on_admin_fee})",
              },
              type: "expression",
              expression: "{charge_dict.charge_code_2.total}", // Floating Point Proof
            },
            {
              title: {
                en: "Insurance fees in RWF to be deducted from the loan amount ({insurance_fee}) ",
                rw: "Amafaranga y'ubwishingizi akurwa kunguzanyo yemejwe ({insurance_fee})",
              },
              type: "expression",
              expression: "{charge_dict.charge_code_3.total}", // Floating Point Proof
            },
            {
              title: {
                en: "Loan interest in RWF ({product_interest_rate}% per year)",
                rw: "Inyungu ku ngunzanyo mu mwaka mu RWF ({product_interest_rate}% ku mwaka)",
              },
              name: "custom_float_5",
              type: "expression",
              expression: "{total_interest}", // Floating Point Proof
            },
            {
              title: {
                en: "Total loan amount to be disbursed: approved loan amount minus fees",
                rw: "Igiteranyo cy'Amafaranga yinguzanyo yemejwe gutangwa",
              },
              name: "custom_float_6",
              type: "expression",
              expression: "fixed(subtract({amount},{charge_dict.charge_code_1.total},{charge_dict.charge_code_2.total},{charge_dict.charge_code_3.total})", // Floating Point Proof
            },
            {
              title: {
                en: "Total loan amount to be repaid: approved loan amount plus interest",
                rw: "Igiteranyo cy' Amafaranga yinguzanyo yemejwe gusubizwa",
              },
              name: "custom_float_7",
              type: "expression",
              expression: "fixed(add({amount}, {total_interest}))", // Floating Point Proof
            },
          ],
        },
        {
          name: "guarantee",
          type: "panel",
          title: {
            en: "Guarantee",
            rw: "Umwishingizi",
          },
          elements: [
            {
              type: "html",
              html: {
                en: `The Borrower provides {cooperative_name} as guarantee for the loan. Additionally, the Cooperative acts
                      as a guarantor for the fulfillment of the Borrower's obligations and guarantees its member, who is the
                      applicant for this loan.`,
                rw: `Ugurizwa atanze {cooperative_name} nk'ingwate y'inguzanyo. Byongeye kandi, Koperative ihinduka kuba ingwate
                      yo kuzuza inshingano z'uwagurijwe kandi ikanishingira umunyamuryango wayo, usaba iyi nguzanyo.`,
              },
            },
            {
              type: "html",
              html: {
                en: `The cooperative agrees to pay on behalf of the borrower in the event that he or she becomes insolvent. The
                      cooperative will pay all debts owed to the Lender by the cooperative member.`,
                rw: `Koperative yemeye kwishyura mu izina ry'uwagurijwe mu gihe ananiwe kwishyura umwenda. Koperative izishyura
                      umwenda wose kutanga inguzanyo.`,
              },
            },
          ],
        },
        {
          name: "payment",
          type: "panel",
          title: {
            en: "Payment",
            rw: "Ubwishyu",
          },
          elements: [
            {
              type: "html",
              html: {
                en: `The borrower authorizes the cooperative to automatically deduct the amount corresponding to the loan
                      repayment directly from the production income.`,
                rw: `Ugurizwa aha uburenganzira koperative guhita itangira byihuse kwishyura
                      inguzanyo ikuramo amafaranga biturutse ku nyungu iva k'umusaruro.`
              }
            },
            {
              type: "html",
              visibleIf: "{repayment_period} == 6",
              html: {
                en: `The amount should be repaid within {repayment_period} months from the date of signing {today_date}.`,
                rw: `Amafaranga agomba kwishyurwa mugihe cyamezi {repayment_period} uhereye umunsi wasinyiye
                      aya masezerano {today_date}.`
              }
            },
            {
              type: "html",
              visibleIf: "{repayment_period} == 12",
              html: {
                en: `The borrower commits to repay {monthly_installment} RWF, half the amount of the loan and interest within 6 months from the date of signing {today_date} and 
                      {monthly_installment} RWF, the other half of the loan and interest within 12 months of signing. The complete amount should be repaid 
                      before {repayment_date}.`,
                rw: `Niyemeje kwishyura {monthly_installment} RWF, kimwe cyakabiri cy’inguzanyo n'inyungu mu mezi atandatu guhera {today_date} na {monthly_installment} RWF,
                      ikindi gice cy’inguzanyo n’inyungu mu mezi 12 . Amafaranga yose azishyurwa mbere {repayment_date}.`,
              },
            },
          ]
        },
        {
          name: "use_of_loan",
          type: "panel",
          title: {
            en: "Use of the Loan",
            rw: "Icyo inguzanyo izakoreshwa ",
          },
          elements: [
            {
              type: "html",
              html: {
                en: "The Borrower will utilize the borrowed funds exclusively for farming activities. ",
                rw: "Uwagurijwe azakoresha inguzanyo gusa mubikorwa byo guhinga.",
              },
            },
          ],
        },
        {
          name: "late_payment",
          type: "panel",
          title: {
            en: "Late Payment",
            rw: "Gutinda kwishyura",
          },
          elements: [
            {
              type: "html",
              html: {
                en: `The borrower acknowledges and commits to ensuring timely payments, demonstrating
                      their dedication. In the event of late payment, a charge of 6% RWF will be applied for
                      each month the payment is overdue.`,
                rw: `Ugurizwa aremera kandi yiyemeza kwishyura ku gihe, agaragaza ubwitange bwe. Mu gihe yatinze
                      kwishyura inguzanyo, azacibwa amafaranga angana na 6% ya RWF buri kwezi yakereweho kwishyura
                      nkigihano cyuwatinze kwishyura inguzanyo.`,
              },
            },
          ],
        },
        payment_methods,
        {
          name: "law",
          type: "panel",
          title: {
            en: "Law",
            rw: "Amategeko",
          },
          elements: [
            {
              type: "html",
              html: {
                en: `Pursuant to Law No. 73/2018 dated 31/08/2018, its article 4 determines the method of transmission of information
                      on debts in Rwanda, the borrower gives MoneyPhone the right to inform the status of the loan granted to anybody
                      responsible and authorized by law.  Both parties declare that they have read and understood the terms and conditions
                      of this loan agreement and accept it voluntarily.`,
                rw: `Hashingiwe ku itegeko N0 73/2018 ryo kuwa 31/08/2018 ingingo yaryo ya 4 rigena uburyo bw'ihererekanyamakuru ku
                      myenda mu Rwanda, ugurijwe ahaye MoneyPhone uburenganzira bwo kumenyesha imiterere y'inguzanyo yahawe urwego
                      urwo arirwo rwose rubishinzwe kandi rwemewe n'amategeko. Impande zombi ziremeranya ibikubiye muraya masezerano.`,
              },
            },
          ],
        },
        {
          name: "sign",
          type: "panel",
          title: {
            en: "Signature",
            rw: "Utanga inguzanyo",
          },
          elements: [
            {
              name: "sign_name",
              type: "text",
              isRequired: true,
              title: {
                en: "Your full name",
                rw: "Amazina yombi ",
              },
            },
            {
              title: { en: "Sign", rw: "Umukono" },
              type: "signaturepad",
              isRequired: true,
              name: "signature",
            },
          ],
        },
      ],
    },
  ],
};

export const sign_rwanda_direct_model_default = {
  showProgressBar: "top",
  showQuestionNumbers: "off",
  sendResultOnPageNext: true,

  firstPageIsStarted: false,

  showCompletedPage: false,
  completeText: {
    en: "Submit loan application",
    rw: "Ohereza ubuse bw'inguzanyo",
  },

  questionStartIndex: 1,
  requiredText: "",

  pages: [
    // Page 1: Terms and conditions
    page_1,
    // Page 2: Loan Agreement
    {
      title: {
        en: "Loan Agreement",
        rw: "Amasezerano y'inguzanyo",
      },
      name: "review",
      innerIndent: 1,

      elements: [
        {
          type: "expression",
          name: "today_date",
          expression: "daysFromNow(0, 0, 'rw')",
          title: { en: "Date", rw: "Italiki" },
        },
        {
          type: "expression",
          name: "repayment_date",
          expression: "monthsFromNow({repayment_period}, 0, 'rw')",
          title: "Repayment date created to be called in the html section",
          visibleIf: "false"
        },
        {
          name: "total_overview",
          type: "panel",
          visibleIf: "{amount} notempty and {repayment_period} notempty",
          elements: [
            {
              type: "html",
              html: {
                en: "Between:",
                rw: "Hagati : ",
              },
            },
            {
              type: "html",
              html: {
                en: `MoneyPhone Africa LTD, residing in Rwanda and represented by Jean Paul Mushumba, hereinafter
                      referred to as the "Lender,"`,
                rw: `MoneyPhone Africa LTD, ikigo cy'ubucuruzi gitanga inguzanyo kibarizwa mu Rwanda kikaba
                      gihagarariwe na Jean Paul Mushumba, aha akaba ariwe uguriza.`,
              },
            },
            {
              type: "html",
              html: {
                en: "and",
                rw: "na",
              },
            },
            {
              type: "html",
              html: {
                en: `{first_name} {last_name}, residing at {province}, {district}, {home_country}, {cell}, {village},
                      hereinafter referred to as the "Borrower".`,
                rw: `ugurizwa, {first_name} {last_name}, utuye {province}, {district}, {home_country}, {cell}, {village},
                      aha akaba ariwe ugurizwa.`,
              },
            },
            {
              type: "html",
              visibleIf: "{intake.custom_text_7} == 'yes'",
              html: {
                en: 'With bank account No. {bank_account}, bank name {bank_name}',
                rw: "Ufite Konti ya Banki Nomero {bank_account} ibarizwa muri banki {bank_name}",
              },
            },
            {
              type: "html",
              visibleIf: "{intake.custom_text_5} == 'yes'",
              html: {
                en: "With mobile wallet {mobile}, with phone number {phone_number}",
                rw: "Na Konti ya MOMO yanditswe kuri {mobile} ufite nomero ya tel {phone_number}",
              },
            },
            {
              type: "html",
              html: {
                en: "All parties agree to enter into the following loan agreement, subject to the following terms and conditions:",
                rw: "Impande zombi zemeranyijwe kugirana amasezerano yinguzanyo, hashingiwe ku ngingo zikurikira:",
              },
            },
          ],
        },
        {
          name: "total_overview",
          type: "panel",
          title: {
            en: "Loan Terms",
            rw: "Ingingo zigize aya masezerano y'inguzanyo",
          },
          description: {
            en: "The borrower is committing to pay the lender the amount borrowed in accordance with the following:",
            rw: "Ugurizwa yiyemeje kwishyura uwamugurije umwenda akurikije ibi bikurikira:",
          },
          visibleIf: "{amount} notempty and {repayment_period} notempty",
          elements: [
            {
              name: "loan_terms",
              valueName: "loan_terms",
              type: "expression",
              expression:
                "loan_terms", // Floating Point Proof
              title: "Technical loan terms (NOT visible for user)",
              visibleIf: "{amount} < 0",
            },
            {
              title: {
                en: "Requested loan amount in RWF",
                rw: "Amafaranga y'inguzanyo asabwa",
              },
              type: "expression",
              expression: "{requested_amount}",
            },
            {
              title: {
                en: "Approved loan amount in RWF",
                rw: "Amafaranga y'inguzanyo yemejwe",
              },
              type: "expression",
              expression: "{amount}", // Floating Point Proof
            },
            {
              title: {
                en: "Loan term (in months)",
                rw: "Igihe inguzanyo izamara (Mu mezi)",
              },
              name: "repayment_period",
              type: "expression",
              expression: "{repayment_period}", // Floating Point Proof
            },
            {
              title: {
                en: "Total administrative fees in RWF to be deducted from the loan amount ({admin_fee})",
                rw: "Amafaranga ya serivise y'ubuyobozi angana na {admin_fee} akurwa kunguzanyo yemejwe",
              },
              type: "expression",
              expression: "{charge_dict.charge_code_1.total}", // Floating Point Proof
            },
            {
              title: {
                en: "Total VAT on administrative fees in RWF to be deducted from the loan amount ({vat_on_admin_fee})",
                rw: "Umusoro(VAT) ukurwa kunguzanyo yemejwe ({vat_on_admin_fee})",
              },
              type: "expression",
              expression: "{charge_dict.charge_code_2.total}", // Floating Point Proof
            },
            {
              title: {
                en: "Insurance fees in RWF to be deducted from the loan amount ({insurance_fee}) ",
                rw: "Amafaranga y'ubwishingizi akurwa kunguzanyo yemejwe ({insurance_fee})",
              },
              type: "expression",
              expression: "{charge_dict.charge_code_3.total}", // Floating Point Proof
            },
            {
              title: {
                en: "Loan interest in RWF ({product_interest_rate}% per year)",
                rw: "Inyungu ku ngunzanyo mu mwaka mu RWF ({product_interest_rate}% ku mwaka)",
              },
              name: "custom_float_5",
              type: "expression",
              expression: "{total_interest}", // Floating Point Proof
            },
            {
              title: {
                en: "Total loan amount to be disbursed: approved loan amount minus fees",
                rw: "Igiteranyo cy'Amafaranga yinguzanyo yemejwe gutangwa",
              },
              name: "custom_float_6",
              type: "expression",
              expression: "fixed(subtract({amount},{charge_dict.charge_code_1.total},{charge_dict.charge_code_2.total},{charge_dict.charge_code_3.total})", // Floating Point Proof
            },
            {
              title: {
                en: "Total loan amount to be repaid: approved loan amount plus interest",
                rw: "Igiteranyo cy' Amafaranga yinguzanyo yemejwe gusubizwa",
              },
              name: "custom_float_7",
              type: "expression",
              expression: "fixed(add({amount}, {total_interest}))", // Floating Point Proof
            },
          ],
        },
        {
          name: "guarantee",
          type: "panel",
          title: {
            en: "Guarantee",
            rw: "Umwishingizi",
          },
          elements: [
            {
              type: "html",
              html: {
                en: `The Borrower provides {cooperative_name} as guarantee for the loan. Additionally, the Cooperative acts
                      as a guarantor for the fulfillment of the Borrower's obligations and guarantees its member, who is the
                      applicant for this loan.`,
                rw: `Ugurizwa atanze {cooperative_name} nk'ingwate y'inguzanyo. Byongeye kandi, Koperative ihinduka kuba ingwate
                      yo kuzuza inshingano z'uwagurijwe kandi ikanishingira umunyamuryango wayo, usaba iyi nguzanyo.`,
              },
            },
            {
              type: "html",
              html: {
                en: `The cooperative agrees to pay on behalf of the borrower in the event that he or she becomes insolvent. The
                      cooperative will pay all debts owed to the Lender by the cooperative member.`,
                rw: `Koperative yemeye kwishyura mu izina ry'uwagurijwe mu gihe ananiwe kwishyura umwenda. Koperative izishyura
                      umwenda wose kutanga inguzanyo.`,
              },
            },
          ],
        },
        {
          name: "payment",
          type: "panel",
          title: {
            en: "Payment",
            rw: "Ubwishyu",
          },
          elements: [
            {
              type: "html",
              visibleIf: "{repayment_period} == 6",
              html: {
                en: `The borrower commits to repay the full amount of the loan within {repayment_period} months from the date of signing {today_date}.
                      The amount to be repaid should be before {repayment_date}.`,
                rw: `Usaba inguzanyo yemeye kwishyura amafaranga yose y'inguzanyo mu gihe cy'amezi {repayment_period} kuva igihe yasinyiye aya
                      masezerano {today_date}. Amafaranga agomba kwishyurwa agomba gutangwa mbere {repayment_date}.`,
              },
            },
            {
              type: "html",
              visibleIf: "{repayment_period} == 12",
              html: {
                en: `The borrower commits to repay {monthly_installment} RWF, half the amount of the loan and interest within 6 months from the date of signing {today_date} and 
                      {monthly_installment} RWF, the other half of the loan and interest within 12 months of signing. The complete amount should be repaid 
                      before {repayment_date}.`,
                rw: `Niyemeje kwishyura {monthly_installment} RWF, kimwe cyakabiri cy’inguzanyo n'inyungu mu mezi atandatu guhera {today_date} na {monthly_installment} RWF,
                      ikindi gice cy’inguzanyo n’inyungu mu mezi 12 . Amafaranga yose azishyurwa mbere {repayment_date}.`,
              },
            }, 
          ],  
        },
        {
          name: "use_of_loan",
          type: "panel",
          title: {
            en: "Use of the Loan",
            rw: "Icyo inguzanyo izakoreshwa ",
          },
          elements: [
            {
              type: "html",
              html: {
                en: "The Borrower will utilize the borrowed funds exclusively for farming activities. ",
                rw: "Uwagurijwe azakoresha inguzanyo gusa mubikorwa byo guhinga.",
              },
            },
          ],
        },
        {
          name: "late_payment",
          type: "panel",
          title: {
            en: "Late Payment",
            rw: "Gutinda kwishyura",
          },
          elements: [
            {
              type: "html",
              html: {
                en: `The borrower acknowledges and commits to ensuring timely payments, demonstrating
                      their dedication. In the event of late payment, a charge of 6% RWF will be applied for
                      each month the payment is overdue.`,
                rw: `Ugurizwa aremera kandi yiyemeza kwishyura ku gihe, agaragaza ubwitange bwe. Mu gihe yatinze
                      kwishyura inguzanyo, azacibwa amafaranga angana na 6% ya RWF buri kwezi yakereweho kwishyura
                      nkigihano cyuwatinze kwishyura inguzanyo.`,
              },
            },
          ],
        },
        payment_methods,
        {
          name: "law",
          type: "panel",
          title: {
            en: "Law",
            rw: "Amategeko",
          },
          elements: [
            {
              type: "html",
              html: {
                en: `Pursuant to Law No. 73/2018 dated 31/08/2018, its article 4 determines the method of transmission of information
                      on debts in Rwanda, the borrower gives MoneyPhone the right to inform the status of the loan granted to anybody
                      responsible and authorized by law.  Both parties declare that they have read and understood the terms and conditions
                      of this loan agreement and accept it voluntarily.`,
                rw: `Hashingiwe ku itegeko N0 73/2018 ryo kuwa 31/08/2018 ingingo yaryo ya 4 rigena uburyo bw'ihererekanyamakuru ku
                      myenda mu Rwanda, ugurijwe ahaye MoneyPhone uburenganzira bwo kumenyesha imiterere y'inguzanyo yahawe urwego
                      urwo arirwo rwose rubishinzwe kandi rwemewe n'amategeko. Impande zombi ziremeranya ibikubiye muraya masezerano.`,
              },
            },
          ],
        },
        {
          name: "sign",
          type: "panel",
          title: {
            en: "Signature",
            rw: "Utanga inguzanyo",
          },
          elements: [
            {
              name: "sign_name",
              type: "text",
              isRequired: true,
              title: {
                en: "Your full name",
                rw: "Amazina yombi ",
              },
            },
            {
              title: { en: "Sign", rw: "Umukono" },
              type: "signaturepad",
              isRequired: true,
              name: "signature",
            },
          ],
        },
      ],
    },
  ],
};

export const sign_rwanda_direct_model_tea = {
  showProgressBar: "top",
  showQuestionNumbers: "off",
  sendResultOnPageNext: true,

  firstPageIsStarted: false,

  showCompletedPage: false,
  completeText: {
    en: "Submit loan application",
    rw: "Ohereza ubuse bw'inguzanyo",
  },

  questionStartIndex: 1,
  requiredText: "",

  pages: [
    // Page 1: Terms and conditions
    page_1,
    // Page 2: Loan Agreement
    {
      title: {
        en: "Loan Agreement",
        rw: "Amasezerano y'inguzanyo",
      },
      name: "review",
      innerIndent: 1,

      elements: [
        {
          type: "expression",
          name: "today_date",
          expression: "daysFromNow(0, 0, 'rw')",
          title: { en: "Date", rw: "Italiki" },
        },
        {
          name: "total_overview",
          type: "panel",
          visibleIf: "{amount} notempty and {repayment_period} notempty",
          elements: [
            {
              type: "html",
              html: {
                en: "Between:",
                rw: "Hagati : ",
              },
            },
            {
              type: "html",
              html: {
                en: `MoneyPhone Africa LTD, residing in Rwanda and represented by Jean Paul Mushumba, hereinafter
                      referred to as the "Lender,"`,
                rw: `MoneyPhone Africa LTD, ikigo cy'ubucuruzi gitanga inguzanyo kibarizwa mu Rwanda kikaba
                      gihagarariwe na Jean Paul Mushumba, aha akaba ariwe uguriza.`,
              },
            },
            {
              type: "html",
              html: {
                en: "and",
                rw: "na",
              },
            },
            {
              type: "html",
              html: {
                en: `{first_name} {last_name}, residing at {province}, {district}, {home_country}, {cell}, {village},
                      hereinafter referred to as the "Borrower".`,
                rw: `ugurizwa, {first_name} {last_name}, utuye {province}, {district}, {home_country}, {cell}, {village},
                      aha akaba ariwe ugurizwa.`,
              },
            },
            {
              type: "html",
              visibleIf: "{intake.custom_text_7} == 'yes'",
              html: {
                en: 'With bank account No. {bank_account}, bank name {bank_name}',
                rw: "Ufite Konti ya Banki Nomero {bank_account} ibarizwa muri banki {bank_name}",
              },
            },
            {
              type: "html",
              visibleIf: "{intake.custom_text_5} == 'yes'",
              html: {
                en: "With mobile wallet {mobile}, with phone number {phone_number}",
                rw: "Na Konti ya MOMO yanditswe kuri {mobile} ufite nomero ya tel {phone_number}",
              },
            },
            {
              type: "html",
              html: {
                en: "All parties agree to enter into the following loan agreement, subject to the following terms and conditions:",
                rw: "Impande zombi zemeranyijwe kugirana amasezerano yinguzanyo, hashingiwe ku ngingo zikurikira:",
              },
            },
          ],
        },
        {
          name: "total_overview",
          type: "panel",
          title: {
            en: "Loan Terms",
            rw: "Ingingo zigize aya masezerano y'inguzanyo",
          },
          description: {
            en: "The borrower is committing to pay the lender the amount borrowed in accordance with the following:",
            rw: "Ugurizwa yiyemeje kwishyura uwamugurije umwenda akurikije ibi bikurikira:",
          },
          visibleIf: "{amount} notempty and {repayment_period} notempty",
          elements: [
            {
              name: "loan_terms",
              valueName: "loan_terms",
              type: "expression",
              expression:
                "loan_terms", // Floating Point Proof
              title: "Technical loan terms (NOT visible for user)",
              visibleIf: "{amount} < 0",
            },
            {
              title: {
                en: "Requested loan amount in RWF",
                rw: "Amafaranga y'inguzanyo asabwa",
              },
              type: "expression",
              expression: "{requested_amount}",
            },
            {
              title: {
                en: "Approved loan amount in RWF",
                rw: "Amafaranga y'inguzanyo yemejwe",
              },
              type: "expression",
              expression: "{amount}", // Floating Point Proof
            },
            {
              title: {
                en: "Loan term (in months)",
                rw: "Igihe inguzanyo izamara (Mu mezi)",
              },
              name: "repayment_period",
              type: "expression",
              expression: "{repayment_period}", // Floating Point Proof
            },
            {
              title: {
                en: "Total administrative fees in RWF to be deducted from the loan amount ({admin_fee})",
                rw: "Amafaranga ya serivise y'ubuyobozi angana na {admin_fee} akurwa kunguzanyo yemejwe",
              },
              type: "expression",
              expression: "{charge_dict.charge_code_1.total}", // Floating Point Proof
            },
            {
              title: {
                en: "Total VAT on administrative fees in RWF to be deducted from the loan amount ({vat_on_admin_fee})",
                rw: "Umusoro(VAT) ukurwa kunguzanyo yemejwe ({vat_on_admin_fee})",
              },
              type: "expression",
              expression: "{charge_dict.charge_code_2.total}", // Floating Point Proof
            },
            {
              title: {
                en: "Insurance fees in RWF to be deducted from the loan amount ({insurance_fee}) ",
                rw: "Amafaranga y'ubwishingizi akurwa kunguzanyo yemejwe ({insurance_fee})",
              },
              type: "expression",
              expression: "{charge_dict.charge_code_3.total}", // Floating Point Proof
            },
            {
              title: {
                en: "Loan interest in RWF ({product_interest_rate}% per year)",
                rw: "Inyungu ku ngunzanyo mu mwaka mu RWF ({product_interest_rate}% ku mwaka)",
              },
              name: "custom_float_5",
              type: "expression",
              expression: "{total_interest}", // Floating Point Proof
            },
            {
              title: {
                en: "Total loan amount to be disbursed: approved loan amount minus fees",
                rw: "Igiteranyo cy'Amafaranga yinguzanyo yemejwe gutangwa",
              },
              name: "custom_float_6",
              type: "expression",
              expression: "fixed(subtract({amount},{charge_dict.charge_code_1.total},{charge_dict.charge_code_2.total},{charge_dict.charge_code_3.total})", // Floating Point Proof
            },
            {
              title: {
                en: "Total loan amount to be repaid: approved loan amount plus interest",
                rw: "Igiteranyo cy' Amafaranga yinguzanyo yemejwe gusubizwa",
              },
              name: "custom_float_7",
              type: "expression",
              expression: "fixed(add({amount}, {total_interest}))", // Floating Point Proof
            },
            {
              title: {
                en: "Monthly amount",
                rw: "Ubwishyu bwa buri kwezi",
              },
              name: "custom_float_8",
              type: "expression",
              expression: "fixed(divide({custom_float_7}, {repayment_period}))",
            },
          ],
        },
        {
          name: "guarantee",
          type: "panel",
          title: {
            en: "Guarantee",
            rw: "Umwishingizi",
          },
          elements: [
            {
              type: "html",
              html: {
                en: `The Borrower provides {cooperative_name} as guarantee for the loan. Additionally, the Cooperative acts
                      as a guarantor for the fulfillment of the Borrower's obligations and guarantees its member, who is the
                      applicant for this loan.`,
                rw: `Ugurizwa atanze {cooperative_name} nk'ingwate y'inguzanyo. Byongeye kandi, Koperative ihinduka kuba ingwate
                      yo kuzuza inshingano z'uwagurijwe kandi ikanishingira umunyamuryango wayo, usaba iyi nguzanyo.`,
              },
            },
            {
              type: "html",
              html: {
                en: `The cooperative agrees to pay on behalf of the borrower in the event that he or she becomes insolvent. The
                      cooperative will pay all debts owed to the Lender by the cooperative member.`,
                rw: `Koperative yemeye kwishyura mu izina ry'uwagurijwe mu gihe ananiwe kwishyura umwenda. Koperative izishyura
                      umwenda wose kutanga inguzanyo.`,
              },
            },
          ],
        },
        {
          name: "payment",
          type: "panel",
          title: {
            en: "Payment",
            rw: "Ubwishyu",
          },
          elements: [
            {
              type: "html",
              html: {
                en: `The borrower authorizes the cooperative to automatically deduct the amount corresponding to the monthly loan
                      repayment directly from the production income.`,
                rw: `Uwagurijwe yemerera koperative guhita ikuramo amafaranga ahwanye no kwishyura inguzanyo ya buri kwezi bivuye
                      mu musaruro.`
              }
            },
            {
              type: "html",
              html: {
                en: `The amount should be repaid within {repayment_period} months from the date of signing {today_date}. Each monthly payment
                      shall be {custom_float_8} in RWF.`,
                rw: `Amafaranga agomba kwishyurwa mugihe cyamezi {repayment_period} uhereye umunsi wasinyiye {today_date}.
                      Ubwishyu bwa buri kwezi bugomba kuba {custom_float_8} muri RWF.`
              }
            }, 
            {
              type: "html",
              html: {
                en: `MoneyPhone will receive monthly payments in the amount of {custom_float_8}. The borrower is aware that
                      she/he will have to repay the full loan amount of {amount} RWF plus {custom_float_5} RWF for expenses.`,
                rw: `MoneyPhone izajya yakira ubwishyu  buri kwezi bungana na {custom_float_8}.
                      Uwagurijwe azi neza ko agomba kwishyura amafaranga yinguzanyo yuzuye
                      {amount} RWF wongeyeho {custom_float_5} RWF y'ibyakoreshejwe(depense).`
              }
            }
          ]
        },
        {
          name: "use_of_loan",
          type: "panel",
          title: {
            en: "Use of the Loan",
            rw: "Icyo inguzanyo izakoreshwa ",
          },
          elements: [
            {
              type: "html",
              html: {
                en: "The Borrower will utilize the borrowed funds exclusively for farming activities. ",
                rw: "Uwagurijwe azakoresha inguzanyo gusa mubikorwa byo guhinga.",
              },
            },
          ],
        },
        {
          name: "late_payment",
          type: "panel",
          title: {
            en: "Late Payment",
            rw: "Gutinda kwishyura",
          },
          elements: [
            {
              type: "html",
              html: {
                en: `The borrower acknowledges and commits to ensuring timely monthly payments, demonstrating their
                      dedication. In the event of late payment, a charge of 6% RWF will be applied for each month
                      the payment is overdue.`,
                rw: `Ugurizwa aremera kandi yiyemeza kwishyura buri kwezi ku gihe, agaragaza ubwitange bwe. Mu gihe
                      yatinze kwishyura inguzanyo, azacibwa amafaranga angana na 6% ya RWF buri kwezi yakerewe
                      kwishyura nkigihano cyuwatinze kwishyura inguzanyo.`,
              },
            },
          ],
        },
        payment_methods,
        {
          name: "law",
          type: "panel",
          title: {
            en: "Law",
            rw: "Amategeko",
          },
          elements: [
            {
              type: "html",
              html: {
                en: `Pursuant to Law No. 73/2018 dated 31/08/2018, its article 4 determines the method of transmission of information
                      on debts in Rwanda, the borrower gives MoneyPhone the right to inform the status of the loan granted to anybody
                      responsible and authorized by law.  Both parties declare that they have read and understood the terms and conditions
                      of this loan agreement and accept it voluntarily.`,
                rw: `Hashingiwe ku itegeko N0 73/2018 ryo kuwa 31/08/2018 ingingo yaryo ya 4 rigena uburyo bw'ihererekanyamakuru ku
                      myenda mu Rwanda, ugurijwe ahaye MoneyPhone uburenganzira bwo kumenyesha imiterere y'inguzanyo yahawe urwego
                      urwo arirwo rwose rubishinzwe kandi rwemewe n'amategeko. Impande zombi ziremeranya ibikubiye muraya masezerano.`,
              },
            },
          ],
        },
        {
          name: "sign",
          type: "panel",
          title: {
            en: "Signature",
            rw: "Utanga inguzanyo",
          },
          elements: [
            {
              name: "sign_name",
              type: "text",
              isRequired: true,
              title: {
                en: "Your full name",
                rw: "Amazina yombi ",
              },
            },
            {
              title: { en: "Sign", rw: "Umukono" },
              type: "signaturepad",
              isRequired: true,
              name: "signature",
            },
          ],
        },
      ],
    },
  ],
};