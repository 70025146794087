import { SurveyComponent } from "../../../components/survey/survey_component";
import { expressions } from "../../../survey/validators";
import env from "../../../env";

const required = env.ENDPOINT !== 'local';

export const references = new SurveyComponent({
  title: {
    en: 'References',
    ar: 'المعرّفون'
  },
  name: 'references',

  elements: [

    {
      type: "html",
      name: "reference_desc",
      html: {
        en: `<p>Please list two references whom would like to give you a positive endorsement. For example: your previous employer, colleague, teacher, advisor, etc. Reference can be anyone who can provide an insight into your study and work ethics. Reference should not be one of your guarantors.</p>`,
        ar: `<p>يرجى ذكر اثنين من المعرّفين الذين يرغبون في إعطائك رأيًا إيجابيًا. على سبيل المثال: صاحب العمل السابق، زميل ، معلّم، مستشار ، إلخ. يمكن أن يكون المعرّف هو أي شخص يمكنه تقديم نظرة ثاقبة حول أخلاقيات الدراسة والعمل لديك. لا ينبغي أن يكون المعرّف أحد كفلائك.</p>`
      },
    },

    {
      title: {
        en: "Reference #1",
        ar: "المعرّف الأول"
      },
      name: "panel_ref_1",
      type: "panel",
      innerIndent: 1,
      elements: [
        {
          title: {
            en: "Reference Full Name *",
            ar: "الاسم الرباعي للمعرّف *"
          },
          name: "ref1_name",
          type: "text",
          isRequired: required,
        },
        {
          title: {
            en: "Reference Mobile No. * (Please include the country code. Ex. 962791234567)",
            ar: "رقم الهاتف للمعرّف * (يرجى إدخال رمز البلد. مثال: 961291234567)"
          },
          name: "ref1_phone",
          type: "phone",
          visibleIf: "{ref1_name} notempty",
          isRequired: required,
          validators: [
            expressions.phone_en('ref1_phone'),
            expressions.phone_length('ref1_phone')
          ]
        },
        {
          title: {
            en: "What is the relation of {ref1_name} to you? *",
            ar: "ما هي علاقة {ref1_name} بك؟ *"
          },
          description: {en: "(Ex. Boss, Friend, Family, etc.)", ar: "(مثال: مدير، صديق، عائلة، إلخ)"},
          name: "ref1_relation",
          type: "text",
          visibleIf: "{ref1_name} notempty",
          isRequired: required,
        }
      ]
    },

    {
      title: {
        en: "Reference #2",
        ar: "المعرّف الثاني"
      },
      name: "panel_ref_2",
      type: "panel",
      innerIndent: 1,
      elements: [
        {
          title: {
            en: "Reference Full Name *",
            ar: "الاسم الرباعي للمعرّف *"
          },
          name: "ref2_name",
          type: "text",
          isRequired: required,
        },
        {
          title: {
            en: "Reference Mobile No. * (Please include the country code. Ex. 962791234567)",
            ar: "رقم الهاتف للمعرّف * (يرجى إدخال رمز البلد. مثال: 961291234567)"
          },
          name: "ref2_phone",
          type: "phone",
          visibleIf: "{ref2_name} notempty",
          isRequired: required,
          validators: [
            expressions.phone_en('ref2_phone'),
            expressions.phone_length('ref2_phone')
          ]
        },
        {
          title: {
            en: "What is the relation of {ref2_name} to you? *",
            ar: "ما هي علاقة {ref2_name} بك؟ *"
          },
          description: {en: "(Ex. Boss, Friend, Family, etc.)", ar: "(مثال: مدير، صديق، عائلة، إلخ)"},
          name: "ref2_relation",
          type: "text",
          visibleIf: "{ref2_name} notempty",
          isRequired: required,
        }
      ]
    },
  ]
});
