import { SurveyComponent } from "../../survey_component";
import env from "../../../../env";

const required = env.ENDPOINT !== "local";

export const HomeCountry = new SurveyComponent({
  name: "home_country",
  type: "text",
  isRequired: required,
  title: {
    en: "Country *",
    ar: "البلد *"
  }
});
