import { SurveyComponent } from "../../survey_component";

export const GuarantorDescription = new SurveyComponent({
  name: "guarantor_description",
  type: "html",
  html: {
    en: `<p>For an individual loan you need a guarantor, together you will be responsible for the loan. If you don't have a guarantor, please contact us.</p>`,
    ar: `<p>ستحتاج إلى كفيل للحصول على قرض، وستكونان معًا مسؤولين عن القرض. إذا لم يكن لديك كفيل، يرجى الاتصال بنا.</p>`,
  },
});
