import { SurveyComponent } from "../../survey_component";
import env from "../../../../env";

const required = env.ENDPOINT !== "local";

export const IdentificationQuestionNickname = new SurveyComponent({
  name: "id_question_nickname",
  type: "text",
  isRequired: required,
  title: {
    en: "Nickname *",
    ar: "الكنية *"
  }
});
