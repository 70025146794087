import { SurveyComponent } from "../../survey_component";
import env from "../../../../env";

const required = env.ENDPOINT !== "local";

export const ProjectOwner = new SurveyComponent({
  title: {
    en: "Project Owner *",
    ar: "اسم صاحب المشروع *"
  },
  name: "project_balance_sheet_project_owner",
  type: "text",
  isRequired: required,
});
