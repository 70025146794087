import { SurveyComponent } from "../../survey_component";
import env from "../../../../env";

const required = env.ENDPOINT !== "local";

export const HomeStreetName = new SurveyComponent({
  name: "home_street",
  type: "text",
  isRequired: required,
  title: {
    en: "Street Name *",
    ar: "اسم الشارع *"
  }
});
