import { SurveyComponent } from "../../../components/survey/survey_component";
import {
  OtherQuestionElectronicWallet,
  OtherQuestionSavingsSubscription,
  OtherQuestionMedicalProgram,
  OtherQuestionRelativeKnown,
  OtherQuestionRelativeRelationship
} from "../../../components/survey/questions/other";

export const OtherInformation = new SurveyComponent({
  name: "page_other_information",
  title: {
    en: "Other Information",
    ar: "معلومات أخرى"
  },
  elements: [
    {
      name: "panel_other_info_2",
      type: "panel",
      elements: [
        OtherQuestionElectronicWallet,
        OtherQuestionSavingsSubscription,
        OtherQuestionMedicalProgram,
        OtherQuestionRelativeKnown,
        OtherQuestionRelativeRelationship
      ]
    }
  ]
});
