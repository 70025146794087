import { SurveyComponent } from "../../survey_component";
import env from "../../../../env";

const required = env.ENDPOINT !== "local";

export const IdentificationSerialNumber = new SurveyComponent({
  name: "id_number",
  type: "text",
  isRequired: required,
  visibleIf: "{id_type} == 'sons_of_jordanian_women_id' || {id_type} == 'sons_of_jordanian_women_id'",
  title: {
    en: "Serial No. *",
    ar: "الرقم المتسلسل *"
  }
});
