import { SurveyComponent } from "../../survey_component";
import env from "../../../../env";

const required = env.ENDPOINT !== "local";

/**
 * @param { { name: string, titel: string } } panel
 * @param { { name: string, titel: string } } policy_slider
 * @param { { name: string, titel: string } } rec_slider
 * @param { { name: string, titel: string } } matrix
 * @param { { name: string, titel: string } } total
 * @param { { name: string, titel: string } } average
 *
 * @returns {SurveyComponent}
 */
export const createPanel = (
  panel,
  policy_slider,
  rec_slider,
  matrix,
  total,
  average
) =>
  new SurveyComponent({
    name: panel.name,
    type: "panel",
    innerIndent: 1,
    title: panel.title,
    elements: [
      {
        name: policy_slider.name,
        title: policy_slider.title,
        type: "nouislider",
        step: 1,
        rangeMin: 0,
        rangeMaxStart: 100,
        maxStep: 1,
        rangeMax: 100,
      },
      {
        name: rec_slider.name,
        title: rec_slider.title,
        type: "nouislider",
        step: 1,
        rangeMin: 0,
        rangeMaxStart: 100,
        maxStep: 1,
        rangeMax: 100,
      },
      {
        title: matrix.title,
        name: matrix.name,
        type: "matrixdropdown",
        isRequired: false,
        columns: [
          {
            title: {
              en: "Good or Normal or Bad",
              ar: "جيد أو عادي أو سيء",
            },
            name: "rating",
            isRequired: required,
            cellType: "dropdown",
            inputType: "string",
            choices: [
              { value: "good", text: { en: "Good", ar: "جيد" } },
              { value: "normal", text: { en: "Normal", ar: "عادي" } },
              { value: "bad", text: { en: "Bad", ar: "سيء" } },
            ],
          },
          {
            title: {
              en: "Amount (JOD)",
              ar: "المبلغ (دينار أردني)",
            },
            name: "amount",
            isRequired: required,
            cellType: "text",
            inputType: "number",
          },
        ],
        rows: [
          { value: "jan", text: "Jan" }, // --
          { value: "feb", text: "Feb" }, // --
          { value: "mar", text: "Mar" }, // --
          { value: "apr", text: "Apr" }, // --
          { value: "may", text: "May" }, // --
          { value: "jun", text: "Jun" }, // --
          { value: "jul", text: "Jul" }, // --
          { value: "aug", text: "Aug" }, // --
          { value: "sep", text: "Sep" }, // --
          { value: "oct", text: "Oct" }, // --
          { value: "nov", text: "Nov" }, // --
          { value: "dec", text: "Dec" }, // --
        ],
      },
      {
        title: total.title,
        type: "expression",
        name: total.name,
        expression: `fixed(sumMatrixdropdown({${matrix.name}}, 'amount'), 2, true)`, // Floating Point Proof
        displayStyle: "currency",
        currency: "JOD",
      },
      {
        title: average.title,
        type: "expression",
        name: average.name,
        expression: `fixed(divide({${total.name}}, 12), 2, true)`, // Floating Point Proof
        displayStyle: "currency",
        currency: "JOD",
      },
    ],
  });

export const MonthlySalesAnalysis = createPanel(
  {
    name: "panel_msa",
    title: {
      en: "Monthly Sales Analysis",
      ar: " تحليل المبيعات الشهري",
    },
  },
  {
    name: "monthy_sales_policy_sales",
    title: {
      en: "Monthly sales policy (Cash) %",
      ar: "سياسة المبيعات الشهريه (نقداً ) %",
    },
  },
  {
    name: "receivables_sales",
    title: {
      en: "Receivables %",
      ar: "ذمم %",
    },
  },
  {
    name: "monthly_sales",
    title: "removeMe",
  },
  {
    name: "total_sales",
    title: {
      en: "Total Annual Sales",
      ar: "إجمالي المبيعات السنوية",
    },
  },
  {
    name: "average_sales",
    title: {
      en: "Total Monthly Sales (Total Annual Sales / 12 months)",
      ar: "إجمالي المبيعات الشهرية  (إجمالي المبيعات السنوية /12 شهر)",
    },
  }
);
