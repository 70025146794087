import { SurveyComponent } from "../../survey_component";
import env from "../../../../env";

const required = env.ENDPOINT !== "local";

export const POBoxNumber = new SurveyComponent({
  title: {
    en: "PO Box *",
    ar: "صندوق البريد *"
  },
  name: "po_box_number",
  type: "text",
  visibleIf: "{has_po_box} == true",
  isRequired: required,
});
