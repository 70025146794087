import { SurveyComponent } from "../../../survey_component";
import { expressions } from "../../../../../survey/validators";
import env from "../../../../../env";

const required = env.ENDPOINT !== "local";

export const SecondGuarantorPhone = new SurveyComponent({
  name: "guarantor_2_phone",
  type: "phone",
  visibleIf: "{guarantor_2_first_name} notempty",
  isRequired: required,
  title: {
    en: "Guarantor Mobile No. * (Please include the country code. Ex. 962791234567)",
    ar: "رقم الهاتف للكفيل * (يرجى إدخال رمز البلد. مثال: 961291234567)"
  },
  validators: [
    expressions.same_phone_number("guarantor_2_phone"),
    expressions.phone_en("guarantor_2_phone"),
    expressions.phone_length("guarantor_2_phone")
  ],
});
