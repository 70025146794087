import { SurveyComponent } from "../../survey_component";
import env from "../../../../env";

const required = env.ENDPOINT !== "local";

export const CurrentJobAddress = new SurveyComponent({
  title: {
    en: "Company Address *",
    ar: "عنوان الشركة *",
  },
  name: "current_job_address",
  isRequired: required,
  type: "comment",
});
