import { SurveyComponent } from "../../../components/survey/survey_component";
import {
  HouseFamilyMemberAmount,
  HouseFamilyMemberWorkStatus,
  HouseFamilyMemberWorkProfession
} from "../../../components/survey/questions/house/family_member";
import {
  HouseRoomAmount,
  HouseHasGasStove,
  HouseHasVacuumCleaner,
  HouseHasAirConditioner,
  HouseHasPersonalComputer,
  HouseHasInternetLine,
  HouseHasSmartphone
} from "../../../components/survey/questions/house";
import { OptionalAttachments } from "../../../components/survey/questions/optional_attachments"


export const SocialPerformanceIndicatorsAndAssets = new SurveyComponent({
  name: "spi_and_assets",
  title: {
    en: "Social Performance Indicators & Assets",
    ar: "مؤشرات الأداء الاجتماعي والأصول"
  },
  elements: [
    OptionalAttachments.data,
    {
      name: "panel_spi_and_assets_1",
      type: "panel",
      elements: [
        HouseFamilyMemberAmount,
        HouseFamilyMemberWorkStatus,
        HouseFamilyMemberWorkProfession,
        HouseRoomAmount
      ]
    },
    {
      name: "panel_spi_and_assets_2",
      type: "panel",
      innerIndent: 1,
      title: {
        en: "In your house, do you have?",
        ar: "في منزلك ، هل لديك؟"
      },
      elements: [
        HouseHasGasStove,
        HouseHasVacuumCleaner,
        HouseHasAirConditioner,
        HouseHasPersonalComputer,
        HouseHasInternetLine,
        HouseHasSmartphone
      ]
    }
  ]
});
