import {en_3rd_agreement_texts, p} from './review_fields'

const i_agree = {
    title: 'removeMe',
    type: "checkbox",
    name: "agree",
    isRequired: true,
    choices: [{
      "value": "Applicant has agreed to loan amount, loan term and total fees in the educational flow.",
      "text": {
        en: "I agree",
        ar: "أنا أوافق"
      }
    }],
    validators: []
  };
  
  const req_loan_amount_text = "Loan Amount (JOD)";
  const loan_terms_text = "Loan Term (Months)";
  
  export const points = (amount, repayment_period, prefix) => `${prefix}
  
  ${req_loan_amount_text}: ${amount}
  [x] I agree
  
  ${loan_terms_text}: ${repayment_period}
  [x] I agree
  
  ${en_3rd_agreement_texts.join("\n")}
  [x] I agree`;
  
  export const EducationalAgreementPageGuarantor = {
    title: {en: 'Signing Application', ar: "توقيع الطلب"},
    name: 'review',
    elements: [
      {
        type: 'panel',
        elements:  [
          {
            title: {
              en: req_loan_amount_text,
              ar: "مبلغ القرض (دينار أردني)"
            },
            type: "expression",
            expression: "{amount}", // Floating Point Proof
          },
  
          { ...i_agree, name: 'agree1' }
        ]
      },
  
      {
        type: 'panel',
        elements:  [
          {
            title: {
              en: loan_terms_text,
              ar: "فترة السداد (أشهر)"
            },
            type: "expression",
            expression: "{repayment_period}", // Floating Point Proof
          },
  
          { ...i_agree, name: 'agree2' }
        ]
      },
  
      {
        type: 'panel',
        elements:  [
          {
            type: 'html',
            name: 'removeThisName',
            title: '',
            html: {
              ar: [
                'سيتم احتساب الرسوم الإدارية وإضافتها إلى مبلغ القرض.',
                'سيتم احتساب الطوابع والضرائب ودفعها من قبل مقدم الطلب على الدفعة الأولى المجدولة.',
                'سيتم إدراج الرسوم الإدارية ورسوم الطوابع والضرائب في العقد الذي يجب توقيعه من جانبك وفقًا لذلك.'
              ].map(p).join(''),
              en: en_3rd_agreement_texts.map(p).join('')
            }
          },
  
          { ...i_agree, name: 'agree3' }
        ]
      },
    ]
  };
  