import { SurveyComponent } from "../../survey_component";
import env from "../../../../env";

const required = env.ENDPOINT !== "local";

export const SpouseCurrentJob = new SurveyComponent({
  name: "current_job_spouse",
  type: "text",
  isRequired: required,
  visibleIf: "{marital} == 'married'",
  title: {
    en: "Spouse Job *",
    ar: "وظيفة الزوج / الزوجة *"
  }
});
