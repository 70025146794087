import {
    SurveyComponent
  } from "../../../components/survey/survey_component";

import Income from '../../../components/survey/questions/income'
import Liabilities from '../../../components/survey/questions/liabilities'

export const income_liabilities = new SurveyComponent({
  title: {
    en: 'Income & Liabilities', ar:
      'الإيرادات والالتزامات'
  },
  name: 'income_and_liabilities',
  elements:
    [
      Income,
      Liabilities
    ]
});
