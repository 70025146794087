import { SurveyComponent } from "../../survey_component";
import env from "../../../../env";

const required = env.ENDPOINT !== "local";

const slider = (title, name) =>
  new SurveyComponent({
    title,
    name,
    required,

    type: "nouislider",
    step: 5,
    rangeMin: 0,
    rangeMaxStart: 800,
    maxStep: 500,
    rangeMax: Infinity,
  });

export const ProjectBalanceSheetCurrentAssets = new SurveyComponent({
  name: "panel_pbs_current_assets",
  type: "panel",
  innerIndent: 1,
  title: {
    en: "Current Assets",
    ar: "أ) الموجودات المتداولة",
  },
  description: {
    ar: "موجودات سائلة",
    en: "Liquid assets",
  },
  elements: [
    slider(
      {
        ar: "النقدية",
        en: "Cash",
      },
      "project_balance_sheet_current_assets_cash"
    ),

    slider(
      {
        ar: "الرصيد المتوفر حسب كشف الحساب",
        en: "Available balance as per statement",
      },
      "project_balance_sheet_available_assets_as_per_statement"
    ),

    slider(
      {
        ar: "ادخارات",
        en: "Savings",
      },
      "project_balance_sheet_current_assets_savings"
    ),

    slider(
      {
        ar: "الودائع (إرفاق كشف الوديعه )",
        en: "Deposits (Attached Deposit Statement)",
      },
      "project_balance_sheet_current_assets_deposits"
    ),

    {
      title: {
        en: "Total liquid assets",
        ar: "اجمالي الموجودات السائلة",
      },
      type: "expression",
      name: "project_balance_sheet_total_liquid_assets",
      expression:
        "fixed(add({project_balance_sheet_current_assets_cash}, {project_balance_sheet_available_assets_as_per_statement}, {project_balance_sheet_current_assets_savings}, {project_balance_sheet_current_assets_deposits}), 2)", // Floating Point Proof
      displayStyle: "currency",
      currency: "JOD",
    },
  ],
});
