import { SurveyComponent } from "../../../components/survey/survey_component";
import env from "../../../env";

const required = env.ENDPOINT !== 'local';

export const academic = new SurveyComponent({
  title: {
    en: 'Student Education Information', ar:
      'معلومات الطالب التعليمية'
  },
  name: 'academic',

  elements:
    [
      {
        title: {
          en: "Major of Study *",
          ar: "تخصص الدراسة *"
        },
        name: "academic_major_study",
        type: "text",
        isRequired: required
      },

      {
        title: {
          en: "GPA (Grade Point Average) *",
          ar: "المعدل التراكمي *"
        },
        description: {
          en: '',
          ar: ''
        },
        name: "academic_gpa",
        type: "text",
        isRequired: false
      },

      {
        title: {
          en: "Academic Hours (Completed) *",
          ar: "الساعات الأكاديمية المكتملة *"
        },
        description: {
          en: '',
          ar: ''
        },
        name: "academic_hours_completed",
        type: "text",
        inputType: "number",
        isRequired: false,
      },

      {
        title: {
          en: "How many academic hours are required to get a degree? *",
          ar: "ما عدد الساعات الأكاديمية المطلوبة للحصول على الشهادة؟ *"
        },
        name: "academic_hours_total",
        type: "text",
        inputType: "number",
        isRequired: required
      },

      {
        title: {
          en: "Total Study Cost (JOD) *",
          ar: "التكلفة الإجمالية للدراسة (دينار أردني) *"
        },
        name: "total_study_cost",
        type: "text",
        inputType: "number",
        isRequired: required
      },
      {
        title: {
          en: "Loan Amount / Study Cost required for this loan (JOD) *",
          ar: "تكلفة القرض / تكلفة الدراسة المطلوبة لهذا القرض (دينار أردني) *"
        },
        name: "amount",
        type: "text",
        inputType: "number",
        isRequired: required
      },
      {
        title: {
          en: "Please upload a document by the educational institution that shows the costs of the academic study which will be covered by this loan. *",
          ar: "يرجى تحميل وثيقة من قبل المؤسسة التعليمية التي تبين تكاليف الدراسة الأكاديمية التي سيتم تغطيتها عن طريق هذا القرض. *"
        },
        description: {
          en: "If you cannot find the document now, you can close this form and come back later.",
          ar: "إذا لم تتمكن من العثور على المستند الآن ، يمكنك إغلاق هذا النموذج والعودة اليه لاحقًا"
        },
        name: "academic_costs_proof",
        type: "file_uploader",
        accept: 'image/*,application/pdf',
        isRequired: required
      },

      {
        title: {
          en: "Within how many months do you wish to repay your loan? *",
          ar: "في غضون كم شهر ترغب في سداد القرض الخاص بك؟ *"
        },
        name: "repayment_period",
        type: "nouislider",
        step: 1,
        rangeMin: 4,
        rangeMax: 60,
        isRequired: required
      }
    ]
});
