import { SurveyComponent } from "../../survey_component";

export const ProjectBalanceSheetTotalEquityAndLiabilities = new SurveyComponent({
  name: "panel_pbs_total_equity_and_liabilities",
  type: "panel",
  innerIndent: 1,
  title: {
    en: "Total Equity and Liabilities",
    ar: "اجمالي الموجودات والمطلوبات"
  },
  elements: [
    {
      title: 'removeMe',
      type: "expression",
      name: "project_balance_sheet_total_equity_and_liabilities",
      expression: "{project_balance_sheet_total_assets}", // Floating Point Proof
      displayStyle: "decimal",
    },
  ],
});
