import { SurveyComponent } from "../../survey_component";
import env from "../../../../env";

const required = env.ENDPOINT !== "local";

export const IdentificationQuestionMother = new SurveyComponent({
  name: "id_question_mother",
  type: "text",
  isRequired: required,
  title: {
    en: "Mother's Name *",
    ar: "اسم الأم *"
  }
});
