import { SurveyComponent } from "../../../survey_component";
import env from "../../../../../env";

const required = env.ENDPOINT !== "local";

export const HouseFamilyMemberAmount = new SurveyComponent({
  name: "house_family_member_amount",
  type: "text",
  inputType: "number",
  isRequired: required,
  title: {
    en: "How many members live in the house? *",
    ar: "كم عدد الأعضاء الذين يعيشون في المنزل؟ *"
  }
});
