import { SurveyComponent } from "../../survey_component";
import env from "../../../../env";

const required = env.ENDPOINT !== "local";

export const IdentificationBack = new SurveyComponent({
  title: {
    en: "Back Image (Attachment) *",
    ar: "الصورة الخلفية *"
  },
  description: {
    en:
      "If you cannot find the document now, you can close this form and come back later.",
    ar:
      "إذا لم تتمكن من العثور على المستند الآن ، يمكنك إغلاق هذا النموذج والعودة اليه لاحقًا"
  },
  name: "id_file_back",
  visibleIf:
    "{id_type} == 'id_card' || {id_type} == 'military_id' || {id_type} == 'sons_of_jordanian_women_id' || {id_type} == 'moi_id'",
  type: "file_uploader",
  accept: "image/*,application/pdf",
  isRequired: required
});
