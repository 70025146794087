import { SurveyComponent } from "../../survey_component";
import env from "../../../../env";

const required = env.ENDPOINT !== "local";

export const GuarantorFirstName = new SurveyComponent({
  name: "guarantor_first_name",
  type: "text",
  isRequired: required,
  title: {
    en: "Guarantor Full Name *",
    ar: "اسم الكفيل الرباعي *",
  },
});
