import { SurveyComponent } from "../survey_component";
import {
  GuarantorFirstName,
  GuarantorInvitation
} from "../questions/guarantor";
import {
  SecondGuarantorPhone,
  SecondGuarantorRelation,
  SecondGuarantorPhoneVerified
} from "../questions/guarantor/second_guarantor";

export const PanelGuarantorTwo = new SurveyComponent({
  name: "panel_guarantor_2",
  type: "panel",
  innerIndent: 1,
  title: {
    en: "Guarantor 2",
    ar: "الكفيل 2",
  },
  elements: [
    GuarantorFirstName
      .clone()
      .setName("guarantor_2_first_name"),
    SecondGuarantorPhone, // Temporary workaround; we should invest in reusable components (see MON-301)
    SecondGuarantorPhoneVerified, // Temporary workaround; we should invest in reusable components (see MON-301)
    GuarantorInvitation
      .clone()
      .setName("guarantor_2_invitation").setVisibleIf("{guarantor_2_first_name} notempty"),
    SecondGuarantorRelation, // Temporary workaround; we should invest in reusable components (see MON-301)
  ],
});
