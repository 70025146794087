import { SurveyComponent } from "../../survey_component";
import env from "../../../../env";

const required = env.ENDPOINT !== "local";

export const IdentificationPassportNumber = new SurveyComponent({
  name: "id_number",
  type: "text",
  isRequired: required,
  visibleIf: "{id_type} == 'passport'",
  title: {
    en: "Passport No. *",
    ar: "رقم جواز السفر *"
  }
});
