import { SurveyComponent } from "../../survey_component";
import env from "../../../../env";

const required = env.ENDPOINT !== "local";

export const IdentificationFront = new SurveyComponent({
    title: { en: "Front Image (Attachment) *", ar: "الصورة الأمامية *" },
    description: {
        en: "If you cannot find the document now, you can close this form and come back later. *",
        ar: "إذا لم تتمكن من العثور على المستند الآن ، يمكنك إغلاق هذا النموذج والعودة اليه لاحقًا *"
    },
    name: "id_file",
    visibleIf: "{id_type} notempty",
    type: "file_uploader",
    accept: 'image/*,application/pdf',
    isRequired: required
})
