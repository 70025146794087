import { SurveyComponent } from "../../../components/survey/survey_component";
import {
  FamilyMartialStatus,
  FamilyMemberAmount
} from "../../../components/survey/questions/family";
import {
  SpouseFullName,
  SpouseNationality,
  SpouseIDNumber,
  SpousePhoneNumber,
  SpouseCurrentJob,
  SpouseJobAddress
} from "../../../components/survey/questions/spouse";
import env from "../../../env";

const required = env.ENDPOINT !== "local";

function picture(num, opts = {}) {
  return {
    title: {
      en: `picture ${num}`,
      ar: `${num} صورة:`
    },
    type: "file_uploader",
    accept: "image/*,application/pdf",
    name: `family_book${num}`,
    isRequired: false,
    ...opts
  };
}

export const family = new SurveyComponent({
  title: {
    en: "Family Information",
    ar: "معلومات العائلة"
  },
  name: "family",
  elements: [
    FamilyMartialStatus,
    FamilyMemberAmount,
    SpouseFullName,
    SpouseNationality,
    SpouseIDNumber,
    SpousePhoneNumber,
    SpouseCurrentJob,
    SpouseJobAddress,
    {
      title: {
        en: "Do you have any children living in your house? *",
        ar: "هل لديك أطفال يعيشون في منزلك؟ *"
      },
      name: "children",
      type: "radiogroup",
      isRequired: required,
      choices: [
        { value: "yes", text: { en: "Yes", ar: "نعم" } },
        { value: "no", text: { en: "No", ar: "لا" } }
      ]
    },
    {
      title: {
        en: "Could you share us the age's of your children? *",
        ar: "هل يمكنك مشاركتنا أعمار أطفالك؟ *"
      },
      name: "children_age",
      valueName: "children_age",
      type: "matrixdynamic",
      minRowCount: 1,
      rowCount: 1,
      isRequired: required,
      addRowText: { en: "Add another child", ar: "إضافة طفل آخر" },
      visibleIf: "{children} == 'yes'",
      columns: [
        {
          title: {
            en: "Please add the age of all your children: *",
            ar: "الرجاء إضافة أعمار جميع أطفالك *:"
          },
          name: "age",
          isRequired: required,
          cellType: "dropdown",
          inputType: "number",
          choices: [
            1,
            2,
            3,
            4,
            5,
            6,
            7,
            8,
            9,
            10,
            11,
            12,
            13,
            14,
            15,
            16,
            17,
            18,
            19,
            20,
            21
          ]
        }
      ]
    },
    {
      title: {
        en: "Other than your family members, do you have anyone who depend on you financially? *",
        ar: "عدا عن أفراد عائلتك ، هل لديك أي شخص يعتمد عليك مالياً؟ *"
      },
      description: {
        en: "(Ex. aunts, parents, cousins, friends)",
        ar: "(مثال: العمات، أولياء الأمور، أبناء العم، الأصدقاء)"
      },
      name: "dependence",
      type: "radiogroup",
      isRequired: required,
      choices: [
        { value: true, text: { en: "Yes", ar: "نعم" } },
        { value: false, text: { en: "No", ar: "لا" } }
      ]
    },
    {
      title: {
        en: "How many people depend on you financially? *",
        ar: "كم من الناس يعتمدون عليك ماليا؟ *"
      },
      description: {
        en: "(Ex. aunts, parents, cousins, friends)",
        ar:
          "(مثلا العمات ، أولياء الأمور ، أبناء العم ، الأصدقاء)"
      },
      name: "dependence_number",
      type: "nouislider",
      step: 1,
      rangeMin: 0,
      rangeMax: 12,
      visibleIf: "{dependence} == true",
      isRequired: required
    },
    {
      title: {
        en: "What is the monthly financial support (JOD)? *",
        ar: "ما هي قيمة الدعم المالي الشهري (دينار أردني)؟ *"
      },
      name: "dependence_amount",
      isRequired: required,
      type: "text",
      inputType: "number",
      visibleIf: "{dependence} == true"
    },
    {
      title: {
        en: "Do you have a family booklet that you can upload? *",
        ar: "هل لديك دفتر عائلة يمكنك تحميله؟ *"
      },
      description: {
        en:
          "If you cannot find the document now, you can close this form and come back later.",
        ar:
          "إذا لم تتمكن من العثور على المستند الآن ، يمكنك إغلاق هذا النموذج والعودة اليه لاحقًا"
      },
      name: "panel_family_book",
      type: "panel",
      isRequired: required,
      innerIndent: 1,
      elements: [
        picture(1, { isRequired: required, name: "family_book" }),
        picture(2, { isRequired: required }),
        picture(3, { isRequired: required }),
        picture(4, { isRequired: required }),
        picture(5, { isRequired: false }),
        picture(6, { isRequired: false })
      ]
    }
  ]
});
