import { FamilyMaritalStatusDropdown } from "../../components/survey/questions/family";
import env from "../../env";
import Config from "../../config";
import { expressions } from "../../survey/validators";
const required = env.ENDPOINT !== "local";

export const intake_demo_pages = [
  // Page 1: Identification
  {
    title: { en: "Identification", ar: "التعريف" },
    elements: [
      {
        title: { 
          en: "What is your first name? *",
          ar: "ما هو اسمك الأول؟"
        },
        type: "text",
        name: "first_name",
        isRequired: required,
      },
      {
        title: {
          en: "What is your last name? *",
          ar: "ما هو اسم عائلتك؟"
        },
        type: "text",
        name: "last_name",
        isRequired: required,
      },
      {
        title: {
          en: "To verify your identity please upload a picture of yourself *",
          ar: "من أجل التحقق من هويتك يرجى تحميل صورة لك"
        },
        type: "file_uploader",
        accept: "image/*,application/pdf",
        name: "picture_1",
        isRequired: required,
      },
      {
        title: {
          en: "What is your date of birth? *",
          ar: "ما هو تاريخ ميلادك؟"
        },
        type: "text",
        inputType: "date",
        name: "date_of_birth",
        isRequired: required,
      },
      {
        title: {
          en: "Gender *",
          ar: "ما هو جنسك؟"
        },
        name: "gender",
        type: "dropdown",
        isRequired: required,
        choices: [
          { value: "male", text: { en: "Male", ar: "ذكر" } },
          { value: "female", text: { en: "Female", ar: "أنثى" } },
        ],
      },
      {
        title: {
          en: "What is your nationality? *",
          ar: "ما هي جنسيتك؟"
        },
        type: "dropdown",
        name: "nationality",
        isRequired: required,
        renderAs: "select2",
        choices: Config.nationalities || [],
      },
      {
        title: {
          en: "What identification document do you have? *",
          ar: "ما هي الوثائق التعريفية التي تملكها؟"
        },
        type: "dropdown",
        name: "id_type",
        isRequired: required,
        choices: [
          {
            value: "civil_or_unified_national_card",
            text: {
              en: "Civil status ID or the Unified National Card",
              ar: "بطاقة الأحوال المدنية أو البطاقة الوطنية الموحدة"
            }
          },
          {
            value: "nationality_certificate",
            text: {
              en: "Nationality certificate",
              ar: "شهادة الجنسية"
            }
          },
          {
            value: "residence_card",
            text: {
              en: "Residence card",
              ar: "بطاقة الإقامة"
            }
          },
          {
            value: "ration_card",
            text: {
              en: "Ration card",
              ar: "البطاقة التموينية"
            }
          },
          {
            value: "passport",
            text: {
              en: "Passport",
              ar: "جواز سفر"
            }
          },
          {
            value: "biometric_voter_card",
            text: {
              en: "The Biometric Voter Card",
              ar: "بطاقة الناخب البيومترية"
            }
          }
        ]
      },
      {
        title: {
          en: "National ID / Document No. *",
          ar: "رقم الهوية الوطنية / رقم الوثيقة"
        },
        type: "text",
        name: "id_number",
        isRequired: required,
      },
      {
        title: {
          en: "Front image of your ID *",
          ar: "الصورة الأمامية لبطاقة هويتك"
        },
        type: "file_uploader",
        name: "id_file",
        accept: "image/*,application/pdf",
        isRequired: required,
      },
      {
        title: {
          en: "Back image of your ID *",
          ar: "الصورة الخلفية لبطاقة هويتك"
        },
        type: "file_uploader",
        name: "id_file_back",        
        accept: "image/*,application/pdf",
        isRequired: required,
      },
    ],
  },
  //Page 2: Family information
  {
    title: { en: "Family Information", ar: "معلومات العائلة" },
    elements: [
      FamilyMaritalStatusDropdown,
      {
        title: { en: "Spouse Information", ar: "Spouse Information" },
        name: "spouse_panel",
        type: "panel",
        visibleIf: "{marital} == 'married'",
        innerIndent: 1,
        elements: [
          {
            title: {
              en: "What is your spouse's full name? *",
              ar: "ما هو اسم زوجك/زوجتك الكامل؟"
            },
            type: "text",
            name: "full_name_spouse",
            isRequired: required,
          },
          {
            title: {
              en: "What is your spouse's nationality? *",
              ar: "ما هي جنسية زوجك/زوجتك"
            },
            type: "dropdown",
            name: "nationality_spouse",
            isRequired: required,
            renderAs: "select2",
            choices: Config.nationalities || [],
          },
          {
            title: {
              en: "What identification document does your spouse have? *",
              ar: "ما هي الوثائق التعريفية التي يملكها زوجك/زوجتك؟"
            },
            type: "dropdown",
            name: "custom_text_1",
            isRequired: required,
            choices: [
              {
                value: "civil_or_unified_national_card",
                text: {
                  en: "Civil status ID or the Unified National Card",
                  ar: "بطاقة الأحوال المدنية أو البطاقة الوطنية الموحدة"
                }
              },
              {
                value: "nationality_certificate",
                text: {
                  en: "Nationality certificate",
                  ar: "شهادة الجنسية"
                }
              },
              {
                value: "residence_card",
                text: {
                  en: "Residence card",
                  ar: "بطاقة الإقامة"
                }
              },
              {
                value: "ration_card",
                text: {
                  en: "Ration card",
                  ar: "البطاقة التموينية"
                }
              },
              {
                value: "passport",
                text: {
                  en: "Passport",
                  ar: "جواز سفر"
                }
              },
              {
                value: "biometric_voter_card",
                text: {
                  en: "The Biometric Voter Card",
                  ar: "بطاقة الناخب البيومترية"
                }
              }
            ]
          },
          {
            title: {
              en: "Spouse's National ID / Document No. *",
              ar: "رقم الهوية الوطنية / رقم الوثيقة الخاص بالزوجـ(ة)"
            },
            type: "text",
            name: "id_number_spouse",
            isRequired: required,
          },
          {
            title: {
              en: "What is your spouse's date of birth?",
              ar: "تاريخ ميلاد الزوجـ(ة)"
            },
            type: "text",
            inputType: "date",
            isRequired: required,
            name: "date_of_birth_spouse",
          },
          {
            title: {
              en: "What is your spouse's mobile number? *",
              ar: "رقم هاتف الزوجـ(ة)"
            },
            type: "phone",
            name: "phone_number_spouse",
            isRequired: required,
            validators: [
              expressions.phone_en("phone_number_spouse"),
              expressions.phone_length("phone_number_spouse"),
            ],
          },
          {
            title: {
              en: "Does your spouse have a job? *",
              ar: "هل لدى الزوجـ(ة) وظيفة؟"
            },
            name: "custom_text_2",
            type: "dropdown",
            isRequired: required,
            choices: [
              { value: "yes", text: { en: "Yes", ar: "نعم" } },
              { value: "no", text: { en: "No", ar: "لا" } },
            ],
          },
          {
            title: {
              en: "What is your spouse's job? *",
              ar: "ما هي وظيفة الزوجـ(ة)"
            },
            type: "text",
            name: "current_job_spouse",
            visibleIf: "{custom_text_2} == 'yes'",
            isRequired: required,
          }
        ]
      },
      {
        title: {
          en: "Number of your dependents",
          ar: "عدد من تعولهم"
        },
        type: "text",
        name: "dependence_number",
        isRequired: required,
        inputType: "number"
      }
    ]
  },
  // Page 3: Address information
  {
    title: { en: "Address Information", ar: "معلومات العنوان" },
    elements: [
      {
        title: {
          en: "Province *",
          ar: "المحافظة"
        },
        type: "text",
        name: "custom_text_3",
        isRequired: required,
      },
      {
        title: {
          en: "What is the city? *",
          ar: "في أي مدينة؟"
        },
        type: "text",
        name: "home_city",
        isRequired: required,
      },
      {
        title: {
          en: "What is your PO box/Street name? *",
          ar: "ما هو اسم الشارع الخاص بك؟"
        },
        type: "text",
        name: "home_street",
        isRequired: required,
      },
      {
        title: {
          en: "Postal code *",
          ar: "الرمز البريدي"
        },
        type: "text",
        name: "po_box_postal_code",
        isRequired: required,
      },
      {
        title: {
          en: "If you have a home phone number, please indicate",
          ar: "اذا كان لديك رقم هاتف أرضي، الرجاء إملاؤه"
        },
        type: "phone",
        isRequired: false,
        name: "custom_text_4",
        validators: [
          expressions.phone_en("custom_text_4"),
          expressions.phone_length("custom_text_4"),
        ],
      },
    ]
  },
  // Page 4: Business information
  {
    title: { en: "Business Information", ar: "معلومات العمل" },
    elements: [
      {
        title: {
          en: "What is the name of your business? *",
          ar: "ما هو اسم مشروعك؟"
        },
        type: "text",
        name: "custom_text_5",
        isRequired: required,
      },
      {
        title: {
          en: "Are you the business owner? *",
          ar: "هل أنت صاحب المشروع؟"
        },
        name: "custom_text_6",
        type: "dropdown",
        isRequired: required,
        choices: [
          {
            value: "yes",
            text: {
              en: "Yes, I am the business owner",
              ar: "نعم ، أنا صاحب العمل"
            } },
          {
            value: "no",
            text: {
              en: "No, I am not the business owner",
              ar: "لا ، أنا لست صاحب العمل "
            }
          },
        ],
      },
      {
        title: {
          en: "What is the name of the business owner(s)? *",
          ar: "ما هو اسم صاحب المشروع؟"
        },
        type: "text",
        name: "custom_text_7",
        isRequired: required,
        visibleIf: "{custom_text_6} == 'no'"
      },
      {
        title: {
          en: "Select the sector for your business *",
          ar: "حدد قطاع عمل مشروعك"
        },
        type: "dropdown",
        name: "custom_text_8",
        isRequired: required,
        choices: [
          {
            value: "agriculture",
            text: {
              en: "Agriculture",
              ar:"الزراعة"
            }},
          {
            value: "contracting",
            text: {
              en: "Contracting",
              ar: "المقاولات"
            }
          },
          {
            value: "education",
            text: {
              en: "Education",
              ar: "التعليم"
            }
          },
          {
            value: "industry",
            text: {
              en: "Industry",
              ar: "الصناعة"
            }
          },
          {
            value: "services",
            text: {
              en: "Services",
              ar: "الخدمات"
            }
          },
          {
            value: "technology",
            text: {
              en: "Technology",
              ar: "التكنولوجيا"
            }
          },
          {
            value: "trading",
            text: {
              en: "Trading",
              ar: "التجارة"
            }
          },
          {
            value: "other",
            text: {
              en: "Other",
              ar: "آخر"
            }
          }
        ]
      },
      {
        title: {
          en: "Please give us a brief description of your business *",
          ar: "يرجى اعطاءنا وصفا موجزاً لمشروعك"
        },
        type: "text",
        name: "custom_text_9",
        isRequired: required
      },
      {
        title: {
          en: "What is the start date of your business? *",
          ar: "ما هو تاريخ بدء مشروعك؟"
        },
        type: "text",
        inputType: "date",
        isRequired: required,
        name: "current_job_duration",
      },
      {
        title: {
          en: "Is your business formally registered? *",
          ar: "هل مشروعك مسجل رسميًا؟"
        },
        name: "custom_text_10",
        type: "dropdown",
        isRequired: required,
        choices: [
          {
            value: "yes",
            text: {
              en: "Yes, it is formally registered",
              ar: "نعم ، تم تسجيله رسميًا"
            }
          },
          {
            value: "no",
            text: {
              en: "No, is not formally registered",
              ar: "لا ، غير مسجل رسميًا "
            }
          },
        ],
      },
      {
        title: {
          en: "Please specify the official registration authority",
          ar: "يرجى تحديد سلطة التسجيل الرسمية",
        },
        type: "text",
        visibleIf: "{custom_text_10}=='yes'", 
        name: "custom_text_30",
        isRequired: required
      },
      {
        title: {
          en: "Please upload your business formal registration documents *",
          ar: "يرجى تحميل وثائق التسجيل الرسمية لمشروعك"
        },
        type: "file_uploader",
        name: "file_1",  
        visibleIf: "{custom_text_10}=='yes'",     
        accept: "image/*,application/pdf",
        isRequired: required,
      },
      {
        title: {
          en: "What is the address of your business? *",
          ar: "ما هو عنوان مشروعك؟"
        },
        type: "text",
        name: "custom_text_11",
        isRequired: required
      },
      {
        title: {
          en: "What is your (expected) net monthly income from this business?",
          ar: "ما هو صافي دخلك الشهري (المتوقع) من هذا العمل؟"
        },
        type: "text",
        name: "custom_float_1",            
        isRequired: required,
        inputType: "number"
      },
      {
        title: {
          en: "What is the size of the paid-up capital? (in Iraqi dinars) *",
          ar: "ما هو حجم رأس المال المدفوع؟ (بالدينار العراقي)"
        },
        type: "text",
        name: "custom_float_2",
        isRequired: required,
        inputType: "number"
      }
    ]
  },
  // Page 5: Business income and expenses
  {
    title: { en: "Business revenue details", ar: "تفاصيل إيرادات الأعمال" },
    elements: [
      {
        title: {
          en: "Business Income Details",
          ar: "تفاصيل دخل المشروع"
        },
        name: "custom_jsonfield_1",
        valueName: "custom_jsonfield_1",
        type: "matrixdynamic",
        minRowCount: 1,
        maxRowCount: 5,
        rowLayout: "vertical",
        rowCount: 1,
        addRowLocation: "bottom",
        isRequired: required,
        addRowText: { en: "Add" },
        columns: [
          {
            title: {
              en: "Description *",
              ar: "الوصف"
            },
            cellType: "text",
            name: "description",
            isRequired: required
          },
          {
            title: {
              en: "Amount *",
              ar: "المبلغ"
            },
            cellType: "text",
            name: "amount",            
            isRequired: required,
            inputType: "number"
          }
        ]
      },
      {
        title: {
          en: "Please attach the annual income statement document",
          ar: "برجاء إرفاق مستند بيان الدخل السنوي"
        },
        type: "file_uploader",
        name: "file_2",
        accept: "image/*,application/pdf",
      },
      {
        title: {
          en: "Please attached the anual balance sheet",
          ar: "يرجى إرفاق الميزانية العمومية السنوية"
        },
        type: "file_uploader",
        name: "file_5",
        accept: "image/*,application/pdf",
      },
      {
        title: {
          en: "Please attached the annual cashflow statement",
          ar: "برجاء إرفاق بيان التدفق النقدي السنوي"
        },
        type: "file_uploader",
        name: "file_6",
        accept: "image/*,application/pdf",
      },
      {
        title: {
          en: "If already prepared, please attached the feasility study or business plan of the proposed project",
          ar: "إذا تم إعدادها بالفعل، يرجى إرفاق دراسة الجدوى أو خطة العمل للمشروع المقترح"
        },
        type: "file_uploader",
        name: "file_7",
        accept: "image/*,application/pdf",
      },
      {
        title: {
          en: "Business Expenses Details",
          ar: "تفاصيل مصاريف المشروع"
        },
        name: "custom_jsonfield_2",
        valueName: "custom_jsonfield_2",
        type: "matrixdynamic",
        minRowCount: 1,
        maxRowCount: 5,
        rowLayout: "vertical",
        rowCount: 1,
        addRowLocation: "bottom",
        isRequired: required,
        addRowText: { en: "Add" },
        columns: [
          {
            title: {
              en: "Description *",
              ar: "الوصف"
            },
            cellType: "text",
            name: "description",
            isRequired: required
          },
          {
            title: {
              en: "Amount *",
              ar: "المبلغ"
            },
            cellType: "text",
            name: "amount",
            isRequired: required,
            inputType: "number"
          }
        ]
      },
      {
        title: {
          en: "Please attach proof expenses document *",
          ar: "يرجى إرفاق مستند إثبات النفقات"
        },
        type: "file_uploader",
        name: "file_3",
        accept: "image/*,application/pdf",
      },
      {
        title: {
          en: "Do you have any debts with financial institutions? *",
          ar: "هل لديك ديون مع مؤسسات مالية؟"
        },
        name: "custom_text_12",
        type: "dropdown",
        isRequired: required,
        choices: [
          { value: "yes", text: { en: "Yes", ar: "نعم" } },
          { value: "no", text: { en: "No", ar: "لا" } },
        ],
      },
      {
        title: {
          en: "Debts with Financial Institutions",
          ar: "الديون مع المؤسسات المالية"
        },
        visibleIf: "{custom_text_12} == 'yes'",
        name: "custom_jsonfield_3",
        valueName: "custom_jsonfield_3",
        type: "matrixdynamic",
        minRowCount: 1,
        maxRowCount: 5,
        rowLayout: "vertical",
        rowCount: 1,
        addRowLocation: "bottom",
        isRequired: required,
        addRowText: { en: "Add" },
        columns: [
          {
            title: {
              en: "Name of Institution *",
              ar: "اسم المؤسسة"
            },
            cellType: "text",
            name: "institution",
            isRequired: required
          },
          {
            title: {
              en: "Amount *",
              ar: "المبلغ"
            },
            cellType: "text",
            name: "amount",
            isRequired: required,
            inputType: "number"
          }
        ]
      },
    ]
  },
  // Page 6: Job information
  {
    title: { en: "Job information", ar: "معلومات الوظيفة" },
    elements: [
      {
        title: {
          en: "Do you have a source of income other than this business?",
          ar: "هل لديك مصدر دخل آخر غير هذا العمل؟"
        },
        name: "custom_text_13",
        type: "dropdown",
        isRequired: required,
        choices: [
          { value: "yes", text: { en: "Yes", ar: "نعم ، لدي عمل" } },
          { value: "no", text: { en: "No", ar: "لا ، ليس لدي عمل " } },
        ],
      },
      {
        title: {
          en: "Please indicate the source of income.",
          ar: "يرجى الإشارة إلى مصدر الدخل​"
        },
        name: "custom_text_31",
        type: "text",
        visibleIf: "{custom_text_13} == 'yes'",
        isRequired: required,
      },
      {
        title: {
          en: "Please specify your monthly income.",
          ar: "يرجى تحديد دخلك الشهري"
        },
        intputType: "number",
        name: "custom_float_6",
        type: "text",
        visibleIf: "{custom_text_13} == 'yes'",
        isRequired: required,
      },
    ]
  },
  // Page 7: Loan information
  {
    title: { en: "Loan/Islamic Financing information", ar: "معلومات قرض/ تمويل إسلامي" },
    elements: [
      {
        title: {
          en: "What is the loan/islamic financing amount you want to apply for? *",
          ar: "ما هو مبلغ قرض/ تمويل إسلامي الذي تريد التقدم للحصول عليه؟"
        },
        type: "text",
        name: "amount",
        isRequired: required,
        validators: [
          expressions.amount_greater_than_zero("amount"),
        ],
      },
      {
        title: {
          en: "In how many months would you like to repay?*",
          ar: "في كم شهر تود السداد؟"
        },
        type: "dropdown",
        name: "repayment_period",
        isRequired: required,
        choices: [
          { value: 12 },
          { value: 24 },
          { value: 36 }
        ],
      },
      {
        title: {
          en: "Would you like to have a grace period? *",
          ar: "هل ترغب في الحصول على فترة سماح؟"
        },
        name: "custom_text_15",
        type: "dropdown",
        isRequired: required,
        choices: [
          {
            value: "yes",
            text: {
              en: "Yes, I would like to have a grace period",
              ar: "نعم ، أود الحصول على فترة سماح"
            }
          },
          {
            value: "no",
            text: {
              en: "No, I don't want to have a grace period",
              ar: "لا ، لا أرغب في الحصول على فترة سماح "
            }
          },
        ],
      },
      {
        title: {
          en: "For how many months would you like to have a grace period? *",
          ar: "كم شهر ترغب في الحصول على فترة سماح؟"
        },
        visibleIf: "{custom_text_15} == 'yes'",
        type: "text",
        name: "custom_integer_1",
        isRequired: required,
        inputType: "number"
      },
      {
        title: {
          en: "What type of financial services suit your project?",
          ar: "ما نوع الخدمات المالية التي تناسب مشروعك؟"
        },
        description: {
          en: "You can select more than one option",
          ar: "يمكنك تحديد أكثر من خيار واحد"
        },
        type: "checkbox",
        name: "custom_jsonfield_9",
        isRequired: required,
        choices: [
          {
            value: "Commercial Loan/Islamic Financing (short term)",
            text: {
              en: "Commercial Loan/Islamic Financing (short term)",
              ar: "قرض/ تمويل إسلامي التجاري (قصير الأجل)"
            }
          },
          {
            value: "Investment Loan/Islamic Financing (Long term)",
            text: {
              en: "Investment Loan/Islamic Financing (Long term)",
              ar: "قرض/ تمويل إسلامي الاستثماري (طويل الأجل)"
            }
          },
          {
            value: "Islamic Loan/Islamic Financing",
            text: {
              en: "Islamic Loan/Islamic Financing",
              ar: "قرض/ تمويل إسلامي الإسلامي"
            }
          },
          {
            value: "Microfinance Loan/Islamic Financing",
            text: {
              en: "Microfinance Loan/Islamic Financing",
              ar: "قرض/ تمويل إسلامي التمويل الأصغر"
            }
          }
        ]
      },
      {
        title: {
          en: "What is the status of the project that you need a loan/islamic financing to implement?",
          ar: "ما هو وضع المشروع الذي تحتاج إلى قرض/ تمويل إسلامي لتنفيذه؟",
        },
        name: "custom_text_33",
        type: "radiogroup",
        isRequired: required,
        choices: [
          {
            value: "Establishing a new company",
            text: {
              en: "Establishing a new company",
              ar: "تأسيس شركة جديدة",
            },
          },
          {
            value: "Launching new services or products within the existing company",
            text: {
              en: "Launching new services or products within the existing company",
              ar: "إطلاق خدمات أو منتجات جديدة داخل الشركة القائمة",
            },
          },
          {
            value: "Business expansion within the current company",
            text: {
              en: "Business expansion within the current company",
              ar: "توسيع الأعمال داخل الشركة الحالية",
            },
          },
        ],
      }
    ]
  },
  // Page 8: Collateral information
  {
    title: { en: "Collateral Information", ar: "معلومات الضمان" },
    elements: [
      {
        title: {
          en: "Do you have collateral? *",
          ar: "هل لديك ضمانات؟"
        },
        name: "custom_text_16",
        type: "dropdown",
        isRequired: required,
        choices: [
          {
            value: "yes",
            text: {
              en: "Yes, I have collateral",
              ar: "نعم ، لدي ضمانات"
            }
          },
          {
            value: "no",
            text: {
              en: "No, I don't have collateral",
              ar: "لا ، ليس لدي ضمانات "
            }
          },
        ],
      },
      {
        title: {
          en: "What type of collateral do you own? *",
          ar: "ما نوع الضمان الذي لديك؟"
        },
        description: {
          en: "You can have more than one collateral",
          ar: "يمكنك اختيار أكثر من ضمان واحد"
        },
        visibleIf: "{custom_text_16} == 'yes'",
        type: "checkbox",
        name: "custom_jsonfield_8",
        isRequired: required,
        choices: [
          {
            value: "house",
            text: {
              en: "House",
              ar: "منزل"
            }
          },
          {
            value: "car",
            text: {
              en: "Car",
              ar: "سيارة"
            }
          },
          {
            value: "savings_account",
            text: {
              en: "Savings Account",
              ar: "حساب التوفير"
            }
          },
          {
            value: "land_title",
            text: {
              en: "Land Title",
              ar: "سند ملكية الأرض "
            }
          },
          {
            value: "salary",
            text: {
              en: "Salary",
              ar: "مرتب",
            }
          },
          {
            value: "shares",
            text: {
              en: "Shares",
              ar: "آسهم"
            }
          }
        ]
      },
      // House details
      {
        title: {
          en: "House details",
          ar: "تفاصيل المنزل"
        },
        visibleIf: "{custom_jsonfield_8} contains 'house'",
        name: "custom_jsonfield_4",
        valueName: "custom_jsonfield_4",
        type: "matrixdynamic",
        rowLayout: "vertical",
        rowCount: 1,
        minRowCount: 1,
        maxRowCount: 5,
        isRequired: required,
        columns: [
          {
            title: {
              en: "Address *",
              ar: "العنوان"
            },
            cellType: "text",
            name: "address",
            isRequired: required
          },
          {
            title: {
              en: "Value *",
              ar: "القيمة"
            },
            cellType: "text",
            name: "value",
            isRequired: required,
            inputType: "number"
          },
          {
            title: {
              en: "Upload picture of your house *",
              ar: "تحميل صورة منزلك"
            },
            cellType: "file_uploader",
            name: "house_picture",
            accept: "image/*,application/pdf",
          },
          {
            title: {
              en: "Upload ownership document *",
              ar: "قم بتحميل وثيقة الملكية"
            },
            cellType: "file_uploader",
            name: "ownership_document",
            accept: "image/*,application/pdf",
          },
        ]
      },
      // Car details
      {
        title: {
          en: "Car details",
          ar: "تفاصيل السيارة"
        },
        visibleIf: "{custom_jsonfield_8} contains 'car'",
        name: "custom_jsonfield_5",
        valueName: "custom_jsonfield_5",
        type: "matrixdynamic",
        rowLayout: "vertical",
        rowCount: 1,
        minRowCount: 1,
        maxRowCount: 5,
        isRequired: required,
        columns: [
          {
            title: {
              en: "Year *",
              ar: "السنة"
            },
            cellType: "text",
            name: "year",
            isRequired: required,
            inputType: "number"
          },
          {
            title: {
              en: "Type *",
              ar: "النوع"
            },
            cellType: "text",
            name: "type",
            isRequired: required
          },
          {
            title: {
              en: "Value *",
              ar: "القيمة"
            },
            cellType: "text",
            name: "value",
            isRequired: required,
            inputType: "number"
          },
          {
            title: {
              en: "Upload picture of your car *",
              ar: "قم بتحميل صورة لسيارتك"
            },
            cellType: "file_uploader",
            name: "car_picture",
            accept: "image/*,application/pdf",
          },
          {
            title: {
              en: "Upload ownership document *",
              ar: "قم بتحميل وثيقة الملكية"
            },
            cellType: "file_uploader",
            name: "ownership_document",
            accept: "image/*,application/pdf",
          },
        ]
      },
      // Savings account details
      {
        title: {
          en: "Savings account details",
          ar: "تفاصيل حساب التوفير"
        },
        visibleIf: "{custom_jsonfield_8} contains 'savings_account'",
        name: "custom_jsonfield_6",
        valueName: "custom_jsonfield_6",
        type: "matrixdynamic",
        rowLayout: "vertical",
        rowCount: 1,
        minRowCount: 1,
        maxRowCount: 5,
        isRequired: required,
        columns: [
          {
            title: {
              en: "Bank Name *",
              ar: "اسم البنك"
            },
            cellType: "text",
            name: "bank_name",
            isRequired: required
          },
          {
            title: {
              en: "Bank Number *",
              ar: "رقم البنك"
            },
            cellType: "text",
            name: "bank_number",
            isRequired: required,
            inputType: "number"
          },
          {
            title: {
              en: "Balance *",
              ar: "الرصيد"
            },
            cellType: "text",
            name: "balance",
            isRequired: required,
            inputType: "number"
          },
          {
            title: {
              en: "Upload proof document *",
              ar: "قم بتحميل مستند إثبات"
            },
            cellType: "file_uploader",
            name: "proof_document",
            accept: "image/*,application/pdf",
          },
        ]
      },
      // Land details
      {
        title: {
          en: "Land details",
          ar: "تفاصيل الأرض"
        },
        visibleIf: "{custom_jsonfield_8} contains 'land_title'",
        name: "custom_jsonfield_7",
        valueName: "custom_jsonfield_7",
        type: "matrixdynamic",
        rowLayout: "vertical",
        rowCount: 1,
        minRowCount: 1,
        maxRowCount: 5,
        isRequired: required,
        columns: [
          {
            title: {
              en: "Address *",
              ar: "العنوان"
            },
            cellType: "text",
            name: "address",
            isRequired: required
          },
          {
            title: {
              en: "Size *",
              ar: "المساحة"
            },
            cellType: "text",
            name: "size",
            isRequired: required,
            inputType: "number"
          },
          {
            title: {
              en: "Value *",
              ar: "القيمة"
            },
            cellType: "text",
            name: "value",
            isRequired: required,
            inputType: "number"
          },
          {
            title: {
              en: "Upload proof of ownership *",
              ar: "قم بتحميل وثيقة الملكية"
            },
            cellType: "file_uploader",
            name: "owneship_document",
            accept: "image/*,application/pdf",
          },
        ]
      },
      {
        title: {
          en: "Do you have a guarantor? *",
          ar: "هل لديك ضامن؟"
        },
        name: "custom_text_18",
        type: "dropdown",
        isRequired: required,
        choices: [
          {
            value: "yes",
            text: {
              en: "Yes, I have a guarantor",
              ar: "نعم لدي ضامن"
            }
          },
          {
            value: "no",
            text: {
              en: "No, I don't have a guarantor",
              ar: "لا ، ليس لدي ضامن "
            }
          },
        ],
      },
      {
        title: {
          en: "How many guarantors do you want to add? *",
          ar: "كم عدد الضامنين الذي تريد إضافته؟"
        },
        name: "custom_integer_2",
        type: "dropdown",
        visibleIf: "{custom_text_18} == 'yes'",
        isRequired: required,
        defaultValue: "1",
        choices: [
          { value: 1 },
          { value: 2 },
          { value: 3 }
        ],
      },
      {
        title: { en: "Guarantor Information" },
        name: "guarantor_panel",
        type: "panel",
        visibleIf: "{custom_text_18} == 'yes'",
        innerIndent: 1,
        elements: [
          // Guarantor 1
          {
            title: {
              en: "Guarantor 1",
              ar: "الضامن 1"
            },
            name: "guarantor_1_panel",
            type: "panel",
            innerIndent: 2,
            elements: [
              {
                title: {
                  en: "What type of guarantee do you want to add? *",
                  ar: "ما نوع الضمان الذي تريد إضافته؟"
                },
                name: "custom_text_19",
                type: "dropdown",
                isRequired: required,
                choices: [
                  {
                    value: "government_person",
                    text: {
                      en: "Government employee",
                      ar: "موظف حكومي"
                    }
                  },
                  {
                    value: "salaried_person",
                    text: {
                      en: "Other salaried person",
                      ar: "شخص آخر يتقاضى راتبا "
                    }
                  },
                ],
              },
              {
                title: {
                  en: "Guarantor's name *",
                  ar: "اسم الضامن"
                },
                type: "text",
                name: "guarantor_first_name",
                isRequired: required
              },
              {
                title: {
                  en: "Guarantor's phone number",
                  ar: "رقم هاتف الضامن"
                },
                name: "guarantor_phone",
                type: "phone",
                isRequired: required,
                validators: [
                  expressions.phone_en("guarantor_phone"),
                  expressions.phone_length("guarantor_phone"),
                  expressions.same_phone_number("guarantor_phone"),
                  expressions.compare_phone_numbers("guarantor_phone", "guarantor_2_phone", "phone number of Guarantor 1", "phone number of Guarantor 2"),
                  expressions.compare_phone_numbers("guarantor_phone", "guarantor_3_phone", "phone number of Guarantor 1", "phone number of Guarantor 3")
                ],
              },
              {
                title: {
                  en: "Relationship with the guarantor *",
                  ar: "العلاقة مع الضامن"
                },
                type: "text",
                name: "custom_text_20",
                isRequired: required
              },
            ]
          },
          // Guarantor 2
          {
            title: {
              en: "Guarantor 2",
              ar: "الضامن 2"
            },
            name: "guarantor_2_panel",
            type: "panel",
            visibleIf: "{custom_integer_2} => 2",
            innerIndent: 2,
            elements: [
              {
                title: {
                  en: "What type of guarantee do you want to add? *",
                  ar: "ما نوع الضمان الذي تريد إضافته؟"
                },
                name: "custom_text_28",
                type: "dropdown",
                isRequired: required,
                choices: [
                  {
                    value: "government_person",
                    text: {
                      en: "Government employee",
                      ar: "موظف حكومي"
                    }
                  },
                  {
                    value: "salaried_person",
                    text: {
                      en: "Other salaried person",
                      ar: "شخص آخر يتقاضى راتبا "
                    }
                  },
                ],
              },
              {
                title: {
                  en: "Guarantor's name *",
                  ar: "اسم الضامن"
                },
                type: "text",
                name: "guarantor_2_first_name",
                isRequired: required
              },
              {
                title: {
                  en: "Guarantor's phone number",
                  ar: "رقم هاتف الضامن"
                },
                name: "guarantor_2_phone",
                type: "phone",
                isRequired: required,
                validators: [
                  expressions.phone_en("guarantor_2_phone"),
                  expressions.phone_length("guarantor_2_phone"),
                  expressions.same_phone_number("guarantor_2_phone"),
                  expressions.compare_phone_numbers("guarantor_2_phone", "guarantor_phone", "phone number of Guarantor 2", "phone number of Guarantor 1"),
                  expressions.compare_phone_numbers("guarantor_2_phone", "guarantor_3_phone", "phone number of Guarantor 2", "phone number of Guarantor 3")
                ],
              },
              {
                title: {
                  en: "Relationship with the guarantor *",
                  ar: "العلاقة مع الضامن"
                },
                type: "text",
                name: "custom_text_26",
                isRequired: required
              },
            ]
          },
          // Guarantor 3
          {
            title: {
              en: "Guarantor 3",
              ar: "الضامن 3"
            },
            name: "guarantor_3_panel",
            type: "panel",
            visibleIf: "{custom_integer_2} == 3",
            innerIndent: 2,
            elements: [
              {
                title: {
                  en: "What type of guarantee do you want to add? *",
                  ar: "ما نوع الضمان الذي تريد إضافته؟"
                },
                name: "custom_text_29",
                type: "dropdown",
                isRequired: required,
                choices: [
                  {
                    value: "government_person",
                    text: {
                      en: "Government employee",
                      ar: "موظف حكومي"
                    }
                  },
                  {
                    value: "salaried_person",
                    text: {
                      en: "Other salaried person",
                      ar: "شخص آخر يتقاضى راتبا "
                    }
                  },
                ],
              },
              {
                title: {
                  en: "Guarantor's name *",
                  ar: "اسم الضامن"
                },
                type: "text",
                name: "guarantor_3_first_name",
                isRequired: required
              },
              {
                title: {
                  en: "Guarantor's phone number",
                  ar: "رقم هاتف الضامن"
                },
                name: "guarantor_3_phone",
                type: "phone",
                isRequired: required,
                validators: [
                  expressions.phone_en("guarantor_3_phone"),
                  expressions.phone_length("guarantor_3_phone"),
                  expressions.same_phone_number("guarantor_3_phone"),
                  expressions.compare_phone_numbers("guarantor_3_phone", "guarantor_phone", "phone number of Guarantor 3", "phone number of Guarantor 1"),
                  expressions.compare_phone_numbers("guarantor_3_phone", "guarantor_2_phone", "phone number of Guarantor 3", "phone number of Guarantor 2")
                ],
              },
              {
                title: {
                  en: "Relationship with the guarantor *",
                  ar: "العلاقة مع الضامن"
                },
                type: "text",
                name: "custom_text_27",
                isRequired: required
              },
            ]
          },
        ]
      },      
    ]
  },
  // Page 9: Bank account information
  {
    title: { en: "Bank Account Information", ar: "معلومات الحساب المصرفي" },
    elements: [
      {
        title: {
          en: "Do you have a bank account? *",
          ar: "هل لديك حساب فى البنك؟"
        },
        name: "custom_text_21",
        type: "dropdown",
        isRequired: required,
        choices: [
          {
            value: "yes",
            text: {
              en: "Yes, I have a bank account",
              ar: "نعم ، لدي حساب مصرفي"
            }
          },
          {
            value: "no",
            text: {
              en: "No, I don't have a bank account",
              ar: "لا ، ليس لدي حساب مصرفي "
            }
          },
        ],
      },
      {
        title: {
          en: "Bank Information",
          ar: "المعلومات المصرفية"
        },
        name: "bank_panel",
        type: "panel",
        visibleIf: "{custom_text_21} == 'yes'",
        innerIndent: 1,
        elements: [
          {
            title: {
              en: "What is the name of your bank? *",
              ar: "ما هو اسم البنك الذي تتعامل معه؟"
            },
            type: "text",
            name: "custom_text_22",
            isRequired: required
          },
          {
            title: {
              en: "What is your bank account number? *",
              ar: "ما هو رقم حسابك المصرفي؟"
            },
            type: "text",
            name: "custom_text_23",
            isRequired: required,
          },
          {
            title: {
              en: "What is your bank account balance? *",
              ar: "ما هو رصيد حسابك المصرفي؟"
            },
            type: "text",
            name: "custom_float_5",
            isRequired: required,
            inputType: "number"
          },
          {
            title: {
              en: "Please upload the latest bank statement *",
              ar: "يرجى تحميل آخر كشف حساب بنكي"
            },
            type: "file_uploader",
            name: "file_4",
            accept: "image/*,application/pdf",
            isRequired: required,
          }
        ]
      },
      {
        title: {
          en: "Do you have an E-Wallet? *",
          ar: "هل لديك محفظة إلكترونية؟"
        },
        name: "custom_text_24",
        type: "dropdown",
        isRequired: required,
        choices: [
          {
            value: "yes",
            text: {
              en: "Yes, I have an E-Wallet",
              ar: "نعم ، لدي محفظة إلكترونية"
            }
          },
          {
            value: "no",
            text: {
              en: "No, I don't have an E-Wallet",
              ar: "لا ، ليس لدي محفظة إلكترونية "
            }
          },
        ],
      },
      {
        title: {
          en: "Please select the E-Wallet you have *",
          ar: "الرجاء تحديد المحفظة الإلكترونية التي لديك"
        },
        visibleIf: "{custom_text_24} == 'yes'",
        type: "dropdown",
        name: "custom_text_25",
        isRequired: required,
        choices: [
          { value: "asia_hawalla", text: { en: "Asia Hawalla", ar: "Asia Hawalla" }},
          { value: "fastpay", text: { en: "FasPay", ar: "FasPay" } },
          { value: "nasswallet", text: { en: "NassWallet", ar: "NassWallet" } },
          { value: "zaincash", text: { en: "ZainCash", ar: "ZainCash" } }
        ]
      },
    ],
  }
];
