import { SurveyComponent } from "../../survey_component";
import env from "../../../../env";

const required = env.ENDPOINT !== "local";

export const CurrentJobDescription = new SurveyComponent({
  title: {
    en: "Job Description *",
    ar: "المسمى الوظيفي *",
  },
  name: "current_job_description",
  isRequired: required,
  type: "comment",
});
