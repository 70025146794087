import { SurveyComponent } from "../../survey_component";

export const ProjectBalanceSheetTotalCurrentAssets = new SurveyComponent({
  name: "panel_pbs_total_current_assets",
  type: "panel",
  innerIndent: 1,
  title: {
    en: "Total Current Assets",
    ar: "اجمالي الموجودات المتداولة",
  },
  elements: [
    {
      title: "removeMe",
      type: "expression",
      name: "project_balance_sheet_total_current_assets",
      expression:
        "fixed(add({project_balance_sheet_total_inventory}, {project_balance_sheet_total_liquid_assets}, {project_balance_sheet_total_receivables}), 2)", // Floating Point Proof
      displayStyle: "currency",
      currency: "JOD",
    },
  ],
});
