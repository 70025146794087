import { SurveyComponent } from "../../survey_component";
import { expressions } from "../../../../survey/validators";
import env from "../../../../env";

const isRequired = env.ENDPOINT !== "local";

export const HomeMobilePhoneOne = new SurveyComponent({
  name: "phone_number",
  type: "phone",
  isRequired,
  validators: [
    expressions.phone_en("phone_number"),
    expressions.phone_length("phone_number")
  ],
  title: {
    en: "Mobile No. 1 *",
    ar: "رقم الهاتف 1 *"
  }
});
