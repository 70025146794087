import { SurveyComponent } from "../../../survey_component";
import env from "../../../../../env";

const required = env.ENDPOINT !== "local";

export const HouseFamilyMemberWorkProfession = new SurveyComponent({
  name: "house_family_member_work_profession_lsmomtas",
  type: "radiogroup",
  isRequired: required,
  title: {
    en: "Does a family member work as a legislator, senior management officer, manager, technician or associate specialist? *",
    ar: "هل يعمل أحد أفراد الأسرة كمشرّع أو مسؤول إداري أول أو مدير أو فني أو متخصص؟ *"
  },
  choices: [
    {
      value: true,
      text: {
        en: "Yes",
        ar: "نعم"
      }
    },
    {
      value: false,
      text: {
        en: "No",
        ar: "لا"
      }
    }
  ]
});
