import { SurveyComponent } from "../survey_component";
import env from "../../../env";

const required = env.ENDPOINT !== "local";

export const HasBankaccount = new SurveyComponent({
  title: {
    en: "Do you have a bank account? *",
    ar: "هل لديك حساب فى البنك؟ *"
  },
  name: "bank_account", // Not the best name, but it's already in use in the database.
  type: "radiogroup",
  isRequired: required,
  choices: [
    { value: "yes", text: { en: "Yes", ar: "نعم" } },
    { value: "no", text: { en: "No", ar: "لا" } }
  ]
});
