import {insurance_html, text_direction} from '../../components/survey/review_fields';
import Translations  from '../../config/translations.json';
import Config from '../../config';

const { bus_agree_fields } = Translations

/**
 * PLEASE NOTE, IMPORTANT!
 *
 * When changing labels, change "Signed agreement export"-feature
 * in Django Console AS WELL!
 */
const i_agree = {
  title: 'removeMe',
  type: 'checkbox',
  name: 'agree',
  isRequired: true,
  choices: [
    {
      value:
        'Applicant has agreed to loan amount, loan term and total fees in the educational flow.',
      text: {
        en: 'I agree',
        ar: 'أنا أوافق',
      },
    },
  ],
  validators: [],
};

const map_agree_fields = bus_agree_fields.map(
  (field, index) =>
    (field = {
      type: 'panel',
      elements: [
        {
          type: 'html',
          title: '',
          html: {
            ar: `<p style="margin-top:5px;" dir="${text_direction('ar')}">${field['ar']}</p>`,
            en: `<p style="margin-top:5px;" dir="${text_direction('en')}">${field['ar']}</p>`,
          },
        },
        { ...i_agree, name: `bus_agree${index}` },
      ],
    })
);


export const agreement_mfw = (lt, prefix, locale) =>
  {

    return `<p>${prefix}</p>
      <h5 dir='${text_direction(locale)}'>برامج التأمين الميكروي</h5>
      ${insurance_html}
      <p>[x] I agree</p>
      <ol style="list-style: none;">
      <li>Loan Amount (JOD): ${lt.amount}</li>
      <li>Loan Terms (Months): ${lt.repayment_period}</li>
      <li>Expected Date to Receive the Loan (if granted): ${lt.start_loan_date_translated}</li>
      <li>Expected Monthly Administrative Fees (%): ${lt.monthly_administrative_fees * 100}%</li>
      <li>Expected Total Administrative Fees (JOD): ${lt.total_fees}</li>
      <li>Expected Monthly Installment including Fees (JOD): ${lt.monthly_installment}</li>
      <li>Expected First Installment Date: ${lt.start_repayment_date_translated}</li>
      </ol>
      ${bus_agree_fields.map(agree => `<p dir='${text_direction(locale)}'>${agree['ar']}</p>\n<p>[x] I agree</p>\n`).join('')}
    `;
  };

export const agreement_rw = (lt, prefix) =>
  {

    return `<p>${prefix}</p>
      <h5>Agreement</h5>
      <p>[x] I agree</p>
      <ol style="list-style: none;">
      <li>Loan Amount (RWF): ${lt.amount}</li>
      <li>Loan Terms (Months): ${lt.repayment_period}</li>
      <li>Expected Date to Receive the Loan (if granted): ${lt.start_loan_date_translated}</li>
      <li>Expected Monthly Administrative Fees (%): ${lt.monthly_administrative_fees * 100}%</li>
      <li>Expected Total Administrative Fees (RWF): ${lt.total_fees}</li>
      <li>Expected Monthly Installment including Fees (RWF): ${lt.monthly_installment}</li>
      <li>Expected First Installment Date: ${lt.start_repayment_date_translated}</li>
      </ol>
    `;
  };

export const agreement = (lt, prefix, locale) => {
    return Config.deployment === 'rw'
    ? agreement_rw(lt, prefix)
    : agreement_mfw(lt, prefix, locale);

  }

export const review = {
  showProgressBar: 'top',
  showQuestionNumbers: 'off',
  sendResultOnPageNext: true,

  firstPageIsStarted: false,

  showCompletedPage: false,
  completeText: { en: 'Submit loan application', ar: 'إرسال طلب التمويل' },

  questionStartIndex: 1,
  requiredText: '',

  pages: [
    {
      title: {
        en: 'What kind of loan would you like to receive?',
        ar: 'مجموعة (8) ما نوع التمويل الذي تود الحصول عليه؟',
      },
      name: 'loan',
      elements: [
        {
          name: 'insurance',
          type: 'panel',
          innerIndent: 1,
          elements: [
            {
              type: 'html',
              title: '',
              html: {
                en: `<div dir='${text_direction('en')}' class='sv_p_title'>برامج التأمين الميكروي</div>`,
                ar: `<div dir='${text_direction('ar')}' class='sv_p_title'>برامج التأمين الميكروي</div>`,
              },
            },
            {
              type: 'html',
              html: {
                en: insurance_html,
                ar: insurance_html,
              },
            },
            {
              title: 'removeMe',
              type: 'checkbox',
              name: 'agreeMicroInsuranceProgramms',
              isRequired: true,
              choices: [
                {
                  value:
                    'Applicant has agreed to micro insurance programs in the review flow.',
                  text: {
                    en: 'I agree',
                    ar: 'أنا أوافق',
                  },
                },
              ],
              validators: [],
            },
          ],
        },
      ],
    },

    {
      title: {
        en: 'Review and sign your loan application',
        ar: 'قم بمراجعة وتوقيع طلب التمويل',
      },
      name: 'review',

      elements: [
        {
          name: 'total_overview',
          type: 'panel',
          visibleIf: '{amount} notempty and {repayment_period} notempty',
          elements: [
            {
              name: "loan_terms",
              valueName: "loan_terms",
              type: "expression",
              expression: "calcLoanterms({amount}, {repayment_period}, {monthly_insurance})", // Floating Point Proof
              title: "Technical loan terms (NOT visible for user)",
              visibleIf: "{amount} < 0",
            },
            {
              title: {
                en: 'Loan Amount (JOD)',
                ar: 'قيمة القرض (دينار أردني)',
              },
              type: 'expression',
              expression: '{amount}', // Floating Point Proof
            },
            {
              title: {
                en: 'Loan Terms (Months)',
                ar: 'فترة السداد (أشهر)',
              },
              type: 'expression',
              expression: '{repayment_period}', // Floating Point Proof
            },
            {
              title: {
                en: 'Expected Date to Receive the Loan (if granted)',
                ar: 'التاريخ المتوقع للحصول على القرض (في حال تم منحه)',
              },
              type: 'expression',
              name: 'start_loan_date_translated',
              expression: "getLoanTerm({loan_terms}, 'start_loan_date_translated')" // Floating Point Proof
            },
            {
              title: {
                en: 'Expected Monthly Administrative Fees (%) (Final calculated numbers will be included in the contract)',
                ar: 'الرسوم الإدارية الشهرية المتوقعة (%) (الأرقام النهائية التي يتم احتسابها هي الأرقام المدرجة في العقد)',
              },
              type: 'expression',
              expression: "(getLoanTerm({loan_terms}, 'monthly_administrative_fees') * 100) + '%'" // Floating Point Proof
            },
            {
              title: {
                en: 'Expected Total Administrative Fees (JOD) (Final calculated numbers will be included in the contract)',
                ar: 'إجمالي الرسوم الإدارية المتوقعة (دينار أردني) (الأرقام النهائية التي يتم احتسابها هي الأرقام المدرجة في العقد)',
              },
              type: 'expression',
              expression: "getLoanTerm({loan_terms}, 'total_fees')" // Floating Point Proof
            },
            {
              title: {
                en: 'Expected Monthly Installment including Fees (JOD) (Final calculated numbers will be included in the contract)',
                ar: 'القسط الشهري المتوقع بما يشمل الرسوم (دينار) (الأرقام النهائية التي يتم احتسابها هي الأرقام المدرجة في العقد)',
              },
              type: 'expression',
              expression: "getLoanTerm({loan_terms}, 'total_monthly')" // Floating Point Proof
            },
            {
              title: {
                en: 'Expected First Installment Date (Final calculated date will be included in the contract)',
                ar: 'تاريخ القسط الأول المتوقع (التاريخ النهائي الذي يتم احتسابه هو التاريخ المدرج في العقد)',
              },
              type: 'expression',
              expression: "getLoanTerm({loan_terms}, 'start_repayment_date_translated')" // Floating Point Proof
            },
          ],
        },

        {
          type: 'html',
          visibleIf: "{loan_purpose} == 'education'",
          html: {
            en: `<h5 class="sv_q_title">You are committing to a monthly payment of {total_monthly} JOD per month. You are aware that you will need to repay the whole loan of {amount} JOD plus {total_fees} JOD in fees.</h5 class="sv_q_title">`,
            ar: `<h5 class="sv_q_title">{amount} سألتزم بدفعة سداد تمويل شهرية قيمتها{total_monthly}، وأنا على علم بأنه يجب علي دفع كامل قيمة التمويل  بالإضافة إلى{total_fees}  دينار أردني رسوم</h5>`,
          },
        },

        {
          type: 'html',
          name: 'penalty',
          visibleIf: "{loan_purpose} == 'education'",
          html: {
            en:
              'To ensure that you are serious about a timely monthly payment, you will be charged 0.5 JOD for every day you are late with payment.',
            ar:
              'للتأكد من جديتك بسداد الدفعة الشهرية في وقتها المحدد، سيتم فرض غرامة مالية عليك قيمتها  نصف دينار عن كل يوم تأخير في الدفع',
          },
        },

        {
          type: 'panel',
          visibleIf: "{loan_purpose} == 'education'",
          elements: [
            {
              // First sentence
              type: 'expression',
              name: '',
              title:
                'أنا الموقع أدناه {last_name} {first_name} و احمل الرقم الوطني {id_number}, و حيث انني أرغب بالحصول على تمويل من شركة صندوق المرأة للتمويل الأصغر أقر و أنا بكامل أهليتـــي القانونية بأن دخلي الحالي/المتوقع من مشروعي المطلوب تمويله يبلغ {total_monthly} شهريا.',
            },
            {
              // Second sentence
              type: 'expression',
              name: 'removeThisName',
              title: '',
              description: 'كما اقر بوجود دخول اخرى (شخصية و عائلية) و هي:',
            },

            {
              title: 'removeThisName',
              description:
                'بالدينار الأردني، ما المعدل الشهري للدخل خلال الأشهر الثلاثة الماضية؟', // Average 3 months
              name: 'other_incomes',
              valueName: 'other_incomes',
              type: 'matrixdynamic',
              isRequired: false,
              readOnly: true,
              columns: [
                {
                  title: 'Income in JOD', // ---
                  name: 'amount',
                  isRequired: false,
                  cellType: 'text',
                  inputType: 'number',
                },
                {
                  title: 'Where do you get this income:', // ---
                  name: 'party',
                  isRequired: false,
                  cellType: 'text',
                  inputType: 'text',
                },
              ],
            },

            {
              // One-to-last sentence
              type: 'expression',
              name: 'removeThisName',
              title: '',
              description:
                'وأن هذه الدخول مستمرة و متوقع استمرارها طوال فترة عمر التمويل.',
            },

            {
              // Last sentence
              type: 'expression',
              name: 'removeThisName',
              title: '',
              description:
                'واتعهد بأنني أتحمل كامل المسؤولية عن المعلومات المذكورة أعلاه كاملة" و دون تحمل أدنى مسـؤوليــــة على شركة صندوق المرأة.',
            },

            {
              /*title: {
                en: "I understand the risks of taking out a loan and agree to pay the monthly amount of {total_monthly} JOD.",
              },*/
              title: '',
              type: 'checkbox',
              name: 'agree',
              isRequired: true,
              choices: [
                { value: 'agree', text: { en: 'I agree', ar: 'أنا أوافق' } },
              ],
              validators: [],
            },
          ],
        },

        {
          type: 'panel',
          visibleIf: "{loan_purpose} == 'education'",
          elements: [
            {
              // First sentence
              type: 'expression',
              name: '',
              title:
                'أنا الموقع أدناه، حيث أنني أرغب بالحصول على تمويل من شركة صندوق المرأة للتمويل الأصغر، أقر وأنا بكامل أهليتي القانونية بأن إلتزاماتي المالية تجاه الغير هي كالتالي:',
            },

            {
              title: 'removeThisName',
              name: 'all_liabilities_and_outstanding_loans',
              valueName: 'all_liabilities_and_outstanding_loans',
              type: 'matrixdynamic',
              isRequired: false,
              readOnly: true,
              columns: [
                {
                  title: 'قيمة القرض القائم بالدينار الأردني', // Please add the outstanding loan amount in JOD
                  name: 'outstanding_amount',
                  isRequired: false,
                  cellType: 'text',
                  inputType: 'number',
                },
                {
                  title: 'الجهة المقرضة', // With who do you have the loan? Please add the name of the company, bank or friend:
                  name: 'outstanding_party',
                  isRequired: false,
                  cellType: 'text',
                  inputType: 'text',
                },
                {
                  title: 'Average monthly repayment', //
                  name: 'avg_monthly_repayment',
                  isRequired: false,
                  cellType: 'text',
                  inputType: 'number',
                },
                {
                  title: 'Months until fulfillment', //
                  name: 'months_until_fulfillment',
                  isRequired: false,
                  cellType: 'text',
                  inputType: 'number',
                },
              ],
            },

            {
              // Last sentence
              type: 'expression',
              name: '',
              title:
                'كما واتعهد بأنني أتحمل كامل المسؤولية عن المعلومات المذكورة أعلاه كاملة" و دون تحمل أدنى مسؤولية على شركة صندوق المرأة.',
            },

            {
              title: '',
              type: 'checkbox',
              name: 'truth',
              isRequired: true,
              choices: [
                { value: 'agree', text: { en: 'I agree', ar: 'أنا أوافق' } },
              ],
              validators: [],
            },
          ],
        },
        {
          type: 'panel',
          elements: map_agree_fields,
        },

        {
          title: {
            en: 'Please upload a video or audio file of yourself',
            ar: 'حمل الفيديو أو التسجيل الصوتي الخاص بك',
          },
          description: {
            en:
              'Please upload a video or audio file of yourself, in which you (1) say your name, (2) confirm that the data entered in this application form belongs to you, and (3) that you have filled in this data voluntarily.',
            ar:
              'قم رجاءاً بتحميل فيديو أو تسجيل صوتي لك تذكر فيه اسمك وتقوم بالتأكيد على أن البيانات والمعلومات التي تم تعبئتها في هذا الطلب تعود لك وبأنك قد قمت بتعبئتها بنفسك طوعاً',
          },
          name: 'i_agree_on_video',
          type: 'file_uploader',
          accept: 'video/*,audio/*',
          isRequired: true,
        },

        {
          type: 'text',
          inputType: 'text',
          name: 'sign_name',
          isRequired: true,
          title: {
            en: 'Applicant Full Name',
            ar: 'اسم مقدّم الطلب الكامل (الاسم الرباعي)',
          },
        },

        {
          type: 'text',
          inputType: 'date',
          name: 'sign_date',
          isRequired: true,
          title: {
            en: 'Application Date',
            ar: 'تاريخ الطلب',
          },
        },

        {
          type: 'signaturepad',
          name: 'signature',
          isRequired: true,
          title: {
            en: 'Please enter your signature',
            ar: 'الرجاء التوقيع في المربع أدناه ',
          },
        },
      ],
    },
  ],
};
