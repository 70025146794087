import { SurveyComponent } from "../../../components//survey/survey_component";

import { MonthlySalesAnalysis } from '../../../components/survey/panels/income_statement/monthly_sales_analysis';
import { MonthlyPurchasesAnalysis } from '../../../components/survey/panels/income_statement/monthly_purchase_analysis';
import { OperatingAndFamilyExpenses } from '../../../components/survey/panels/income_statement/operating_and_family_expenses';


/**
 * @param { { name: string, titel: string } } average_purchases
 * @param { { name: string, titel: string } } average_sales
 * 
 * @returns {SurveyComponent}
 */
export const IncomeStatement = (average_purchases, average_sales) => new SurveyComponent({
  title: {
    en: 'Income Statement', ar: 'قائمة الدخل'
  },
  name: 'page_income_statement',
  elements: [
    MonthlySalesAnalysis,
    MonthlyPurchasesAnalysis,
    {
      title: {
        en: "Profit Margin %",
        ar: "هامش الربح"
      },
      name: "profit_margin",
      type: "expression",
      expression: `fixed(divide(subtract({average_sales}, {average_purchases}), {average_sales}) * 100, 0, true)`, // Floating Point Proof
      displayStyle: "percent",
    },
    {
      title: {
        en: "Other Income",
        ar: "دخول اخرى"
      },
      name: "other_income",
      type: "text",
      inputType: "number",
    },
    OperatingAndFamilyExpenses,
  ]
});
