import { SurveyComponent } from "../../../components/survey/survey_component";
import { PanelGuarantors} from "../../../components/survey/panels/guarantors"


export const projectPartners = new SurveyComponent({
  title: {
    en: 'Guarantors',
    ar: 'الكفلاء'
  },
  name: 'group',

  elements: [
    PanelGuarantors,
  ]
});
