import { SurveyComponent } from "../../../components/survey/survey_component";

import {
  CurrentJobAddress,
  CurrentJobCompany,
  CurrentJobDescription,
  CurrentJobHours,
  CurrentJobPhone,
  CurrentJobPosition,
  CurrentJobSalarySlip,
  CurrentJobSalary
} from "../../../components/survey/questions/current_job";

import { HasJob } from "../../../components/survey/questions/has_job";
import env from "../../../env";

const required = env.ENDPOINT !== "local";

export const jobDetails = new SurveyComponent({
  title: {
    en: "Job Information",
    ar: "معلومات الوظيفة",
  },
  name: "job_details",
  elements: [
    HasJob,
    {
      title: {
        en: "Could you share details of your current job with us?",
        ar: "هل يمكنك مشاركتنا بتفاصيل عملك الحالي؟",
      },
      name: "current_jobs",
      valueName: "current_jobs",
      type: "panel",
      visibleIf: "{has_job} == true",
      innerIndent: 1,
      elements: [
        CurrentJobPhone,
        CurrentJobPosition,
        CurrentJobCompany,
        CurrentJobAddress,
        CurrentJobHours,
        CurrentJobSalary,
        CurrentJobSalarySlip,
        {
          title: {
            en: "Do you plan to stay in the same company the upcoming year? *",
            ar: "هل تخطط للبقاء في نفس الشركة في العام المقبل؟ *",
          },
          name: "current_job_keep",
          isRequired: required,
          type: "dropdown",
          choices: [
            {
              value: true,
              text: {
                en: "Yes",
                ar: "نعم",
              },
            },
            {
              value: false,
              text: {
                en: "No",
                ar: "لا",
              },
            },
          ],
        },
        CurrentJobDescription,
      ],
    },
  ],
});
