import { SurveyComponent } from "../../survey_component";

import * as IdTypes from "./options/id_types";
import env from "../../../../env";

const required = env.ENDPOINT !== "local";

export const IdentificationTypePalestinian = new SurveyComponent({
  title: {
    en: "What identification paper do you have? *",
    ar: "ما هي الوثائق التعريفية التي تمتلكها *"
  },
  name: "id_type",
  type: "dropdown",
  visibleIf: "{nationality} == 'palestinian'",
  isRequired: required,
  storeOthersAsComment: false,
  choices: [
    IdTypes.PassportOption,
    IdTypes.TempResidenceGazaStripOption,
    IdTypes.ProofOfNationalityOption,
    IdTypes.TravelDocPalestinianRefugeeOption,
    IdTypes.SonsOfJordanianWomenOption
  ]
});
