import { SurveyComponent } from "../../survey_component";

export const GuarantorInvitation = new SurveyComponent({
  name: "guarantor_invitation",
  type: "html",
  visibleIf: "{guarantor_phone} notempty",
  html: {
    en: `<p>We will use this phone number to send your guarantor a short application form.</p>`,
    ar: `<p>سنستخدم رقم الهاتف هذا لإرسال نموذج طلب قصير للكفيل.</p>`,
  },
});
