import { SurveyComponent } from "../../survey_component";
import env from "../../../../env";

const required = env.ENDPOINT !== "local";

export const IdentificationNationality = new SurveyComponent({
  name: "nationality",
  type: "dropdown",
  isRequired: required,
  storeOthersAsComment: false,
  hasOther: true,
  title: {
    en: "Nationality *",
    ar: "الجنسية *"
  },
  choices: [
    {
      value: "jordan",
      text: {
        en: "Jordanian",
        ar: "أردني"
      }
    },
    {
      value: "palestinian",
      text: {
        en: "Palestinian",
        ar: "فلسطيني"
      }
    },
    {
      value: "syrian",
      text: {
        en: "Syrian",
        ar: "سوري"
      }
    }
  ]
});
