import { SurveyComponent } from "../../survey_component";
import env from "../../../../env";

const required = env.ENDPOINT !== "local";

export const HomeCity = new SurveyComponent({
  name: "home_city",
  type: "text",
  isRequired: required,
  title: {
    en: "City *",
    ar: "المدينة *"
  }
});
