import { SurveyComponent } from "../../survey_component";
import env from "../../../../env";

const required = env.ENDPOINT !== "local";

export const ProjectBalanceSheetFixedAssets = new SurveyComponent({
  name: "panel_pbs_fied_assets",
  type: "panel",
  innerIndent: 1,
  title: {
    en: "Fixed Assets (Machinery and Project Equipment)",
    ar: "ب) الأصول الثابتة (الالات ومعدات المشروع)",
  },
  elements: [
    {
      title: "removeMe",
      name: "project_balance_sheet_fixed_assets",
      type: "matrixdynamic",
      minRowCount: 0,
      rowCount: 1,
      isRequired: required,
      addRowText: { en: "Add", ar: "إضافة" },
      columns: [
        {
          title: { en: "Item", ar: "الصنف" },
          name: "item",
          isRequired: required,
          cellType: "text",
          inputType: "string",
        },
        {
          title: { en: "Quantity", ar: "العدد" },
          name: "quantity",
          isRequired: required,
          cellType: "text",
          inputType: "number",
        },
        {
          title: { en: "Estimated value", ar: "القيمة التقديرية" },
          name: "estimated_value",
          isRequired: required,
          cellType: "text",
          inputType: "number",
        },
      ],
    },
    {
      title: {
        en: "Total Fixed Assets",
        ar: "إجمالي الأصول الثابتة",
      },
      type: "expression",
      name: "project_balance_sheet_total_fixed_assets",
      expression:
        "fixed(multiplyAndSumInArray({project_balance_sheet_fixed_assets}, 'quantity', 'estimated_value'), 2)", // Floating Point Proof
      displayStyle: "currency",
      currency: "JOD",
    },
  ],
});
