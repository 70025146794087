import { SurveyComponent } from "../../survey_component";
import env from "../../../../env";

const required = env.ENDPOINT !== "local";

export const IdentificationNationalNumber = new SurveyComponent({
  name: "id_number",
  type: "text",
  isRequired: required,
  visibleIf: "{id_type} == 'id_card' || {id_type} == 'military_id'",
  title: {
    en: "National ID *",
    ar: "الرقم الوطني *"
  }
});
