import { SurveyComponent } from "../../survey_component";
import env from "../../../../env";

const required = env.ENDPOINT !== "local";

export const CurrentJobSalary = new SurveyComponent({
  title: {
    en: "Monthly Salary (JOD) *",
    ar: "الراتب الشهري (دينار أردني) *",
  },
  name: "current_job_salary",
  isRequired: required,
  type: "text",
  inputType: "number",
});
