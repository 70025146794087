import { SurveyComponent } from "../../survey_component";

export const ProjectBalanceSheetTotalLiabilities = new SurveyComponent({
  name: "panel_pbs_total_liabilities",
  type: "panel",
  innerIndent: 1,
  title: {
    en: "Total Liabilities",
    ar: "إجمالي المطلوبات",
  },
  elements: [
    {
      title: "removeMe",
      type: "expression",
      name: "project_balance_sheet_total_liabilities",
      expression:
        "fixed(add({project_balance_sheet_total_long_term_liabilities}, {project_balance_sheet_total_current_liabilities}), 2)", // Floating Point Proof
      displayStyle: "currency",
      currency: "JOD",
    },
  ],
});
