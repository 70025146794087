import Config from '../../config';
import { DEFAULT_LOCALE, translate } from '../../lib/intl';
import { family } from '../intake/pages/family';
import { home } from '../intake/pages/home';
import { identification } from '../intake/pages/identification';
import { loan } from '../intake/pages/loan';
import { OtherInformation } from '../intake/pages/other_information';
import { personal } from '../intake/pages/personal';
import { phone } from '../intake/pages/phone';
import { SocialPerformanceIndicatorsAndAssets } from '../intake/pages/spi_and_assets';
import { academic } from './pages/academic';
import { BankAccount } from './pages/bank_account';
import { applicant_eduloan } from './pages/eduloan/01_applicant_eduloan';
import { identification_eduloan } from './pages/eduloan/02_identification_eduloan';
import { student_eduloan } from './pages/eduloan/03_student_eduloan';
import { income_eduloan } from './pages/eduloan/04_income_details_eduloan';
import { references_eduloan } from './pages/eduloan/05_references_eduloan';
import { income_liabilities } from './pages/income_and_liabilities';
import { jobDetails } from './pages/job_details';
import { MonthlyCashFlow } from './pages/monthly_cash_flow';
import { projectPartners } from './pages/project_partners';
import { references } from './pages/references';
import { studentPersonal } from './pages/student_personal';

export function educational_intake(locale = DEFAULT_LOCALE) {
  const mfw_pages = [
    // Numbering based on MON-351
    projectPartners,
    references,
    personal,
    studentPersonal.setVisibleIf(
      "{applicant} = 'parent' || {applicant} = 'first_degree_relationship'"
    ),
    identification,
    phone,
    loan,
    family,
    home,
    academic,
    jobDetails,
    income_liabilities,
    MonthlyCashFlow,
    BankAccount,
    SocialPerformanceIndicatorsAndAssets,
    OtherInformation,
  ];

  const eduloan_pages = [
    applicant_eduloan,
    identification_eduloan,
    student_eduloan,
    income_eduloan,
    references_eduloan,
  ];

  const currentDeploymentPages =
    Config.deployment && Config.deployment === 'eduloan' ? eduloan_pages : mfw_pages;

  return {
    showProgressBar: 'top',
    showQuestionNumbers: 'off',
    sendResultOnPageNext: true,
    firstPageIsStarted: false,
    startSurveyText: translate('general.startSurveyText'),
    showCompletedPage: false,
    pagePrevText: translate('general.pagePrevText'),
    pageNextText: translate('general.pageNextText'),
    completeText: translate('general.pageNextText'), // note same as `Next`
    requiredText: '',
    isSinglePage: false, // Can be set to True for review mode
    pages: currentDeploymentPages,
    locale,
  };
}
