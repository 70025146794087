import { SurveyComponent } from "../../survey_component";
import env from "../../../../env";

const required = env.ENDPOINT !== "local";

export const FamilyMartialStatus = new SurveyComponent({
  name: "marital",
  type: "radiogroup",
  isRequired: required,
  title: {
    en: "What is your marital status? *",
    ar: "ما هي حالتك الاجتماعية؟ *"
  },
  choices: [
    {
      value: "single",
      text: {
        en: "Single",
        ar: "أعزب"
      }
    },
    {
      value: "married",
      text: {
        en: "Married",
        ar: "متزوج"
      }
    },
    {
      value: "divorced",
      text: {
        en: "Divorced",
        ar: "مطلق"
      }
    },
    {
      value: "widowed",
      text: {
        en: "Widowed",
        ar: "أرمل"
      }
    }
  ]
});

export const FamilyMaritalStatusDropdown = new SurveyComponent({
  name: "marital",
  type: "dropdown",
  isRequired: required,
  title: {
    en: "What is your marital status? *",
    ar: "ما هي حالتك الاجتماعية؟ *"
  },
  choices: [
    {
      value: "single",
      text: {
        en: "Single",
        ar: "أعزب"
      }
    },
    {
      value: "married",
      text: {
        en: "Married",
        ar: "متزوج"
      }
    },
    {
      value: "divorced",
      text: {
        en: "Divorced",
        ar: "مطلق"
      }
    },
    {
      value: "widowed",
      text: {
        en: "Widowed",
        ar: "أرمل"
      }
    },
  ]
});
