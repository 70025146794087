import { SurveyComponent } from "../../../survey_component";
import env from "../../../../../env";

const required = env.ENDPOINT !== "local";

export const HouseFamilyMemberWorkStatus = new SurveyComponent({
  name: "house_family_member_work_status",
  type: "text",
  inputType: "number",
  isRequired: required,
  title: {
    en: "How many members have worked for at least an hour during the past week? *",
    ar: "كم عدد الأعضاء الذين عملوا لمدة ساعة على الأقل خلال الأسبوع الماضي؟ *"
  }
});
