import { SurveyComponent } from "../../../components/survey/survey_component";
import { PanelGuarantorsEdu } from "../../../components/survey/panels/guarantors";
import env from "../../../env";

const required = env.ENDPOINT !== 'local';

export const projectPartners = new SurveyComponent({
  title: {
    en: 'Applicant and Guarantor',
    ar: 'مقدّم الطلب والكفيل'
  },
  name: 'group',

  elements: [
    {
      title: {
        en: "Who is the applicant? *",
        ar: "من هو مقدّم الطلب؟ *"
      },
      name: "applicant",
      type: "radiogroup",
      isRequired: required,
      choices: [
        { value: 'student', text: { en: "Student (Student Age equals or above 18 years old)", ar: "طالب (عمر الطالب يساوي أو أكبر من 18 عامًا)" } },
        { value: 'parent', text: { en: "Parent (Student Age is less than 18 years old)", ar: "الوالد أو الوالدة (عمر الطالب أقل من 18 سنة)" } },
        { value: 'first_degree_relationship', text: { en: "First Degree Relationship (Student Age is less than 18 years old)", ar: "علاقة قرابة من الدرجة الأولى (عمر الطالب أقل من 18 عامًا)" } }
      ]
    },
    {
      title: {
        en: "Please specify first degree relationship (ex. sister) *",
        ar: "يرجى تحديد علاقة القرابة من الدرجة الأولى (مثال: أخت) *"
      },
      name: "applicant_specification",
      type: "text",
      visibleIf: "{applicant} == 'first_degree_relationship'",
      isRequired: false
    },
    {
      title: {
        en: "Educational Institution *",
        ar: "المؤسسة التعليمية *"
      },
      name: "academic_institution",
      type: "text",
      isRequired: required
    },
    {
      title: {
        en: "Institution Type *",
        ar: "نوع المؤسسة التعليمية *"
      },
      name: "academic_institution_type",
      type: "dropdown",
      isRequired: required,
      choices: [
        {
          value: 'university',
          text: {
            en: "University",
            ar: "جامعة"
          }
        },
        {
          value: 'college',
          text: {
            en: "College",
            ar: "كلية"
          }
        },
        {
          value: 'school',
          text: {
            en: "School",
            ar: "مدرسة"
          }
        },
        {
          value: 'training_center',
          text: {
            en: "Training Center",
            ar: "مركز تدريب"
          }
        },
        {
          value: 'other',
          text: {
            en: "Other",
            ar: "أخرى"
          }
        }
      ]
    },
    PanelGuarantorsEdu,
  ]
});
