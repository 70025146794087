import { SurveyComponent } from "../../survey_component";
import { expressions } from "../../../../survey/validators";

export const HomeMobilePhoneTwo = new SurveyComponent({
  name: "mobile_phone_number_2",
  type: "phone",
  isRequired: false,
  validators: [
    expressions.phone_en("mobile_phone_number_2"),
    expressions.phone_length("mobile_phone_number_2")
  ],
  title: {
    en: "Mobile No. 2",
    ar: "رقم الهاتف 2"
  }
});
