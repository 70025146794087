import { SurveyComponent } from "../../survey_component";
import env from "../../../../env";

const required = env.ENDPOINT !== "local";

const slider = (title, name) =>
  new SurveyComponent({
    title,
    name,
    required,

    type: "nouislider",
    step: 5,
    rangeMin: 0,
    rangeMaxStart: 800,
    maxStep: 500,
    rangeMax: Infinity,
  });

export const ProjectBalanceSheetCurrentLiabilities = new SurveyComponent({
  name: "panel_pbs_current_liabilities",
  type: "panel",
  innerIndent: 1,
  title: {
    en: "Current Liabilities",
    ar: "أ ) مطلوبات متداولة",
  },
  elements: [
    slider(
      {
        ar: "الذمم  الدائنة لشهر(ديون المشروع)",
        en: "Accounts Payable for the Month (Project Debt)",
      },
      "project_balance_sheet_current_liabilities_accounts_1_month"
    ),

    slider(
      {
        ar: "الذمم الدائنة لأقل من سنه (ديون المشروع)",
        en: "Accounts payable less than one year (project debt)",
      },
      "project_balance_sheet_current_liabilities_accounts_less_1_year"
    ),

    slider(
      {
        ar: "مطلوبات أخرى",
        en: "Other liabilities",
      },
      "project_balance_sheet_current_liabilities_other"
    ),

    {
      title: {
        en: "Total Current Liabilities",
        ar: "اجمالي المطلوبات المتداوله",
      },
      type: "expression",
      name: "project_balance_sheet_total_current_liabilities",
      expression:
        "fixed(add({project_balance_sheet_current_liabilities_accounts_1_month}, {project_balance_sheet_current_liabilities_accounts_less_1_year}, {project_balance_sheet_current_liabilities_other}), 0)", // Floating Point Proof
      displayStyle: "currency",
      currency: "JOD",
    },
  ],
});
