import { SurveyComponent } from "../../survey_component";
import env from "../../../../env";

const required = env.ENDPOINT !== "local";

export const IdentificationQuestionBirthplace = new SurveyComponent({
  name: "id_question_birthplace",
  type: "text",
  isRequired: required,
  title: {
    en: "Place of Birth *",
    ar: "مكان الولادة *"
  }
});
