import { SurveyComponent } from "../../survey_component";
import env from "../../../../env";

const required = env.ENDPOINT !== "local";

const slider = (title, name) => new SurveyComponent({
  title, name, required,

  type: "nouislider",
  step: 5,
  rangeMin: 0,
  rangeMaxStart: 800,
  maxStep: 500,
  rangeMax: Infinity
});

export const ProjectBalanceSheetInventory = new SurveyComponent({
  name: "panel_pbs_inventory",
  type: "panel",
  innerIndent: 1,
  title: {
    en: "Inventory",
    ar: "المخزون"
  },
  elements: [
    slider({
      ar: 'اجمالي المخزون',
      en: 'Total Inventory',
    }, 'project_balance_sheet_inventory_total_inventory'),

    {
      title: {
        ar: 'اجمالي المخزون',
        en: 'Total inventory',
      },
      type: "expression",
      name: "project_balance_sheet_total_inventory",
      expression: "{project_balance_sheet_inventory_total_inventory}", // Floating Point Proof
      displayStyle: "decimal",
    },
  ],
});
