import { SurveyComponent } from "../../../components/survey/survey_component";
import {
  IdentificationNationality,
  IdentificationTypeSyrian,
  IdentificationPassportNumber,
  IdentificationNationalNumber, 
  IdentificationDocumentNumber, 
  IdentificationSerialNumber, 
  IdentificationNumber, 
  IdentificationFront,
  IdentificationBack,
  IdentificationFileVerified,
  IdentificationQuestionNickname,
  IdentificationQuestionMother,
  IdentificationQuestionBirthplace,
  IdentificationTypeJordan,
  IdentificationTypePalestinian,
  IdentificationTypeOther,
  IdentificationTypeAll
} from "../../../components/survey/questions/identification";

export const identification = new SurveyComponent({
  name: "identification",
  title: {
    en: "Identification Information",
    ar: "معلومات التعريف"
  },
  elements: [
    IdentificationTypeAll,
    IdentificationNationality,
    IdentificationTypeJordan,
    IdentificationTypePalestinian,
    IdentificationTypeSyrian,
    IdentificationTypeOther,
    IdentificationPassportNumber, 
    IdentificationNationalNumber, 
    IdentificationDocumentNumber, 
    IdentificationSerialNumber, 
    IdentificationNumber, 
    IdentificationFront,
    IdentificationBack,
    IdentificationFileVerified,
    IdentificationQuestionNickname,
    IdentificationQuestionMother,
    IdentificationQuestionBirthplace
  ]
});
