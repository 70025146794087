import { SurveyComponent } from "../../survey_component";

import * as IdTypes from "./options/id_types";

/**
 * This code is being used for correcting Translations.
 * SurveyJs internally uses the first question based on name to translate interpolated
 * texts. So the first found question must contain all possible choices.
 *
 * @author Stephan Meijer <stephan.meijer@teamcoda.com>
 */
export const IdentificationTypeAll = new SurveyComponent({
  name: "id_type",
  type: "dropdown",
  visibleIf: 'false',
  isRequired: false,
  choices: Object.values(IdTypes)
});
