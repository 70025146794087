import { SurveyComponent } from "../../../components/survey/survey_component";
import {
  FamilyMartialStatus,
  FamilyMemberAmount
} from "../../../components/survey/questions/family";
import {
  SpouseFullName,
  SpouseNationality,
  SpouseIDNumber,
  SpousePhoneNumber,
  SpouseCurrentJob,
  SpouseJobAddress
} from "../../../components/survey/questions/spouse";

export const GuarantorMaritalStatus = new SurveyComponent({
  name: "guarantor_marital_status",
  title: {
    en: "Family Information",
    ar: "معلومات العائلة"
  },
  elements: [
    FamilyMartialStatus,
    FamilyMemberAmount,
    SpouseFullName,
    SpouseNationality,
    SpouseIDNumber,
    SpousePhoneNumber,
    SpouseCurrentJob,
    SpouseJobAddress
  ]
});
