import { SurveyComponent } from "../../survey_component";
import env from "../../../../env";

const required = env.ENDPOINT !== "local";

const slider = (title, name) => new SurveyComponent({
  title, name, required,

  type: "nouislider",
  step: 5,
  rangeMin: 0,
  rangeMaxStart: 800,
  maxStep: 500,
  rangeMax: Infinity
});

export const ProjectBalanceSheetLongTermLiabilities = new SurveyComponent({
  name: "panel_pbs_long_term_liabilities",
  type: "panel",
  innerIndent: 1,
  title: {
    en: "Long-term liabilities",
    ar: "مطلوبات طويلة الأجل"
  },
  elements: [
    slider({
      ar: 'ب) مطلوبات طويلة الأجل (تزيد عن سنة واحدة ) للمشروع',
      en: 'Long-term liabilities (more than one year) of the project',
    }, 'project_balance_sheet_long_term_liabilities_more_than_one_year'),

    {
      title: {
        en: "Total long-term liabilities",
        ar: "مطلوبات طويلة الأجل"
      },
      type: "expression",
      name: "project_balance_sheet_total_long_term_liabilities",
      expression: "{project_balance_sheet_long_term_liabilities_more_than_one_year}", // Floating Point Proof
      displayStyle: "decimal",
    },
  ],
});
