import { SurveyComponent } from "../../survey_component";
import env from "../../../../env";

const required = env.ENDPOINT !== "local";

export const IdentificationDocumentNumber = new SurveyComponent({
  name: "id_number",
  type: "text",
  isRequired: required,
  visibleIf: "{id_type} == 'replacement_of_lost' || {id_type} == 'temp_residence_gaza_strip' || {id_type} == 'proof_of_nationality' || {id_type} == 'travel_doc_palestinian_refugee'",
  title: {
    en: "Document No. *",
    ar: "رقم الوثيقة *"
  }
});
