import { SurveyComponent } from "../../survey_component";
import env from "../../../../env";

const required = env.ENDPOINT !== "local";

export const CurrentJobCompany = new SurveyComponent({
  title: {
    en: "Company Name *",
    ar: "اسم الشركة *",
  },
  name: "current_job_company",
  isRequired: required,
  type: "text",
});
