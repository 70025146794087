import { SurveyComponent } from "../../../components/survey/survey_component";
import { expressions } from "../../../survey/validators";
import env from "../../../env";
const required = env.ENDPOINT !== "local";

export const loan = new SurveyComponent({
  title: {
    en: "Outstanding loans",
    ar: "القروض المعلقة",
  },
  name: "loan",
  elements: [
    {
      title: {
        en: "Do you have any outstanding loans you still need to repay? *",
        ar: "هل لديك قروض معلقة لا زال يجب عليك سدادها؟ *",
      },
      description: {
        en: "For example for yourself, your family or your business, where you are the beneficiary or the guarantor.",
        ar: "مثلا قروض لك أو لعائلتك أو مشروعك تكون فيها أنت صاحب القرض أو الكفيل",
      },
      name: "has_outstanding_loans",
      type: "radiogroup",
      isRequired: required,
      choices: [
        { value: true, text: { en: "Yes", ar: "نعم" } },
        { value: false, text: { en: "No", ar: "لا" } },
      ],
    },

    {
      title: {
        en: "Could you share us all your outstanding loans with us? *",
        ar: "هل يمكنك مشاركتنا جميع قروضك المعلقة؟ *",
      },
      name: "outstanding_loans",
      valueName: "outstanding_loans",
      type: "matrixdynamic",
      visibleIf: "{has_outstanding_loans} = true",
      minRowCount: 1,
      rowCount: 1,
      isRequired: required,
      addRowText: { en: "Add another loan", ar: "أضف قرض آخر" },
      columns: [
        {
          title: {
            en: "Please add the outstanding loan amount in JOD: *",
            ar: "من فضلك أضف قيمة القرض المعلق *",
          },
          name: "outstanding_amount",
          isRequired: required,
          cellType: "text",
          inputType: "number",
          validators: [
            expressions.amount_greater_than_zero("outstanding_amount"),
          ],
        },
        {
          title: {
            en: "With who do you have the loan? Please add the name of the company, bank or friend: *",
            ar: "ممن أخذت التمويل؟ من فضلك أضف اسم الشركة أو البنك أو الصديق؟ *",
          },
          name: "outstanding_party",
          isRequired: required,
          cellType: "text",
          inputType: "text",
        },
        {
          title: {
            en: "In JODs, what is the monthly installment for this loan? *",
            ar: "ما هي قيمة الدفعة الشهرية لهذا التمويل بالدينار الأردني؟ *",
          },
          name: "avg_monthly_repayment",
          isRequired: required,
          cellType: "text",
          inputType: "number",
        },
        {
          title: {
            en: "Within how many months do you expect to repay this loan entirely? *",
            ar: "خلال كم شهر تتوقع أن تتمكن من سداد هذا التمويل كاملاً؟ *",
          },
          name: "months_until_fulfillment",
          isRequired: required,
          cellType: "text",
          inputType: "number",
        },
      ],
    },

    {
      title: {
        en: "The total amount of loan you have outstanding is:",
        ar: "قيمة القرض/الديون المعلقة الكاملة هي",
      },
      description: {
        en: "If this is incorrect, please adjust the values above.",
        ar: "إذا كان ذلك غير صحيحاً يرجى تعديل القيم في الأعلى",
      },
      type: "expression",
      name: "total_outstanding_loan",
      visibleIf:
        "sumInArray({outstanding_loans}, 'outstanding_amount') > 0 && {has_outstanding_loans} = true",
      expression:
        "fixed(sumInArray({outstanding_loans}, 'outstanding_amount'), 2)", // Floating Point Proof
      displayStyle: "currency",
      currency: "JOD",
    },
  ],
});
