import { SurveyComponent } from "../../../components/survey/survey_component";
import {
  HomeCountry,
  HomeCity,
  HomeMobilePhoneOne,
  HomeMobilePhoneTwo,
  HomeStreetName,
  HomeBuildingNumber,
  HomeAddressDetails,
  HomeHouseNumber,
  LiveWithFamily
} from "../../../components/survey/questions/home";
import { HasPostOfficBox, POBoxPostalCode, POBoxNumber } from "../../../components/survey/questions/po_box";
import { HouseContract, HouseContractAdditional } from "../../../components/survey/questions/house";
import env from "../../../env";

const required = env.ENDPOINT !== "local";

export const home = new SurveyComponent({
  title: {
    en: "Residence & Home Information",
    ar: "معلومات الإقامة والمنزل"
  },
  name: "home",
  elements: [
    {
      title: {
        en: "Where do you live?",
        ar: "أين تعيش؟"
      },
      name: "panel_home",
      type: "panel",
      innerIndent: 1,
      elements: [
        HomeCountry,
        HomeCity,
        HomeMobilePhoneOne,
        HomeMobilePhoneTwo,
      ]
    },
    {
      title: {
        en: "Address Details *",
        ar: "تفاصيل عنوان المنزل *"
      },
      name: "panel_home_details",
      type: "panel",
      innerIndent: 1,
      elements: [
        HomeStreetName,
        HomeBuildingNumber,
        HomeHouseNumber,
        HomeAddressDetails,
        HasPostOfficBox,
        POBoxPostalCode,
        POBoxNumber,
      ]
    },
    LiveWithFamily,
    {
      title: {
        en: "Monthly rental / mortgage costs (JOD) *",
        ar: "كلفة الإيجار / الرهن العقاري الشهرية(دينار) *"
      },
      name: "cost_rent",
      type: "nouislider",
      step: 10,
      rangeMin: 0,
      rangeMax: 6000,
      isrequired: required
    },
    HouseContract,
    HouseContractAdditional,
  ]
});
