import { SurveyComponent } from "../../survey_component";
import env from "../../../../env";

const required = env.ENDPOINT !== "local";

export const FamilyMemberAmount = new SurveyComponent({
  name: "family_member_amount",
  type: "nouislider",
  isrequired: required,
  step: 1,
  rangeMin: 0,
  rangeMax: 20,
  visibleIf:
    "{marital} == 'married' or {marital} == 'widowed' or {marital} == 'divorced'",
  title: {
    en: "No. of Family Members *",
    ar: "عدد أفراد العائلة *"
  }
});
