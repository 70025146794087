import { SurveyComponent } from "../../survey_component";

export const ProjectBalanceSheetTotalAssets = new SurveyComponent({
  name: "panel_pbs_total_assets",
  type: "panel",
  innerIndent: 1,
  title: {
    en: "Total Assets",
    ar: "إجمالي الموجودات",
  },
  elements: [
    {
      title: "removeMe",
      type: "expression",
      name: "project_balance_sheet_total_assets",
      expression:
        "fixed(add({project_balance_sheet_total_current_assets}, {project_balance_sheet_total_fixed_assets}), 2)", // Floating Point Proof
      displayStyle: "currency",
      currency: "JOD",
    },
  ],
});
