import { createPanel } from './monthly_sales_analysis';

/** @var {SurveyComponent} */
export const MonthlyPurchasesAnalysis = createPanel(
  {
    name: 'panel_mpa',
    title: {
      en: 'Monthly Purchases Analysis',
      ar: ' تحليل المشتريات الشهري'
    }
  },
  {
    name: 'monthy_sales_policy_purchases',
    title: {
      en: 'Monthly sales policy (Cash) %',
      ar: 'سياسة المبيعات الشهريه (نقداً ) %'
    }
  },
  {
    name: 'receivables_purchases',
    title: {
      en: 'Receivables %',
      ar: 'ذمم %'
    }
  },
  {
    name: 'monthly_purchases',
    title: 'removeMe'
  },
  {
    name: 'total_purchases',
    title: {
      en: 'Total Annual Purchases',
      ar: 'إجمالي المشتريات السنوية'
    }
  },
  {
    name: 'average_purchases',
    title: {
      en: 'Total Monthly Purchases (Total Annual Purchases / 12 months)',
      ar: 'إجمالي االمشتريات الشهرية  (إجمالي االمشتريات السنوية /12 شهر)'
    }
  }
)
