import { SurveyComponent } from "../../survey_component";
import env from "../../../../env";

const required = env.ENDPOINT !== "local";

export const SpouseIDNumber = new SurveyComponent({
  name: "id_number_spouse",
  type: "text",
  isRequired: required,
  visibleIf: "{marital} == 'married'",
  title: {
    en: "Spouse National ID / Document No. *",
    ar: "الرقم الوطني / رقم الوثيقة للزوج / الزوجة *"
  }
});
