import { SurveyComponent } from "../../survey_component";
import { expressions } from "../../../../survey/validators";
import env from "../../../../env";

const required = env.ENDPOINT !== "local";

export const CurrentJobPhone = new SurveyComponent({
  title: {
    en: "Work Phone No. *",
    ar: "رقم هاتف العمل *",
  },
  name: "current_job_phone",
  type: "phone",
  isRequired: required,
  validators: [
    expressions.phone_en("current_job_phone"),
    expressions.phone_length("current_job_phone"),
  ],
});
