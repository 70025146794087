import { SurveyComponent } from "../survey_component";
import { PanelGuarantorOne } from "./guarantor_one";
import { PanelGuarantorTwo } from "./guarantor_two";
import { GuarantorDescription } from "../questions/guarantor";
import { GuarantorDescriptionEdu } from "../questions/guarantor/description_edu"

export const PanelGuarantors = new SurveyComponent({
  name: "panel_guarantors",
  type: "panel",
  title: {
    en: "Guarantors",
    ar: "الكفلاء",
  },
  elements: [
    GuarantorDescription,
    PanelGuarantorOne,
    PanelGuarantorTwo,
  ],
});

export const PanelGuarantorsEdu = new SurveyComponent({
  name: "panel_guarantors",
  type: "panel",
  title: {
    en: "Guarantors",
    ar: "الكفلاء",
  },
  elements: [
    GuarantorDescriptionEdu,
    PanelGuarantorOne,
    PanelGuarantorTwo,
  ],
});
