import { SurveyComponent } from "../../survey_component";
import env from "../../../../env";

const required = env.ENDPOINT !== "local";

export const HomeHouseNumber = new SurveyComponent({
  name: "home_house_number",
  type: "text",
  isRequired: required,
  title: {
    en: "House No. *",
    ar: "رقم المنزل *"
  }
});
