import { SurveyComponent } from "../../survey_component";
import env from "../../../../env";

const required = env.ENDPOINT !== "local";

export const POBoxPostalCode = new SurveyComponent({
  title: {
    en: "Postal Code *",
    ar: "الرمز البريدي *"
  },
  name: "po_box_postal_code",
  type: "text",
  visibleIf: "{has_po_box} == true",
  isRequired: required,
});
