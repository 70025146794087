import { SurveyComponent } from "../../survey_component";
import env from "../../../../env";

const required = env.ENDPOINT !== "local";

export const HasPostOfficBox = new SurveyComponent({
  title: {
    en: "Do you have a PO Box? *",
    ar: "هل لديك صندوق بريد؟ *"
  },
  name: "has_po_box",
  type: "radiogroup",
  isRequired: required,
  choices: [
    {
      value: true,
      text: {
        en: "Yes",
        ar: "نعم"
      }
    },
    {
      value: false,
      text: {
        en: "No",
        ar: "لا"
      }
    },
  ]
});
