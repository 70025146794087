import { SurveyComponent } from "../../../components/survey/survey_component";
import {
  IdentificationQuestionBankaccount,
  IdentificationQuestionBankname,
  IdentificationQuestionBankaccountType
} from "../../../components/survey/questions/identification";
import { HasBankaccount } from "../../../components/survey/questions/has_bankaccount";
import { BankAccountBalance } from "../../../components/survey/questions/bank_account/balance";
import { BankAccountStatement } from "../../../components/survey/questions/bank_account/statement";
import { OptionalAttachments } from "../../../components/survey/questions/bank_account/optional_bank_attachments";

export const BankAccount = new SurveyComponent({
  name: "bank_account",
  title: {
    en: "Bank Account",
    ar: "حساب البنك"
  },
  elements: [
    HasBankaccount,
    IdentificationQuestionBankaccount.setVisibleIf("{bank_account} == 'yes'"),
    IdentificationQuestionBankname.setVisibleIf("{bank_account} == 'yes'"),
    IdentificationQuestionBankaccountType.setVisibleIf("{bank_account} == 'yes'"),
    BankAccountBalance.setVisibleIf("{bank_account} == 'yes'"),
    BankAccountStatement,
    OptionalAttachments
  ]
});
