import { SurveyComponent } from "../../survey_component";
import env from "../../../../env";

const required = env.ENDPOINT !== "local";

export const SpouseBirthDate = new SurveyComponent({
  name: "date_of_birth_spouse",
  type: "text",
  inputType: "date",
  isRequired: required,
  visibleIf: "{marital} == 'married'",
  title: {
    en: "Spouse Date of Birth",
    ar: "تاريخ ميلاد الزوج / الزوجة"
  }
});
