import { SurveyComponent } from "../survey_component";
import env from "../../../env";

const required = env.ENDPOINT !== "local";

export const HasJob = new SurveyComponent({
  title: {
    en: "Do you have a current job? *",
    ar: "هل لديك وظيفة حالية؟ *",
  },
  description: {
    en: "Either part-time or full-time, apart from our current project.",
    ar: "إما عمل بدوام جزئي أو بدوام، كامل، بعيداً عن مشروعك الحالي/ فكرة مشروعك الحالية؟",
  },
  name: "has_job",
  type: "radiogroup",
  isRequired: required,
  choices: [
    {
      value: true,
      text: {
        en: "Yes",
        ar: "نعم",
      },
    },
    {
      value: false,
      text: {
        en: "No",
        ar: "لا",
      },
    },
  ],
});
