import { SurveyComponent } from "../../survey_component";

export const GuarantorDescriptionEdu = new SurveyComponent({
  name: "guarantor_description",
  type: "html",
  html: {
    en: `<p>For your loan you need a guarantor, together you will be responsible for the loan. Your guarantor must be your parent, sibling, or spouse. If you’re the parent, you cannot be the guarantor yourself. If you don't have a guarantor, please contact us.</p>`,
    ar: `<p>ستحتاج إلى كفيل للحصول على قرض، وستكونان معًا مسؤولين عن القرض. يجب أن يكون الكفيل هو أحد والديك أو شقيقك أو شقيقتك أو زوجك أو زوجتك. (إذا كنت أحد الوالدين، فلا يمكنك أن تكون الكفيل) إذا لم يكن لديك كفيل، يرجى الاتصال بنا.</p>`,
  },
});
