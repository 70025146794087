import { SurveyComponent } from "../../survey_component";
import env from "../../../../env";

const required = env.ENDPOINT !== "local";

export const IdentificationQuestionBankaccountType = new SurveyComponent({
  name: "id_question_bankaccount_type",
  type: "text",
  isRequired: required,
  title: {
    en: "Bank Account Type *",
    ar: "نوع الحساب البنكي *"
  }
});
