import { SurveyComponent } from "../../survey_component";
import env from "../../../../env";

const required = env.ENDPOINT !== "local";

export const OtherQuestionSavingsSubscription = new SurveyComponent({
  name: "other_question_savings_subscription",
  type: "radiogroup",
  isRequired: required,
  title: {
    en: "Do you want to subscribe to Amani Savings Service? *",
    ar: "هل تريد الاشتراك في خدمة التوفير أماني؟ *"
  },
  choices: [
    {
      value: true,
      text: {
        en: "Yes",
        ar: "نعم"
      }
    },
    {
      value: false,
      text: {
        en: "No",
        ar: "لا"
      }
    }
  ]
});
