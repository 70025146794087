import { SurveyComponent } from '../../../components/survey/survey_component';
import { expressions } from "../../../survey/validators";
import env from "../../../env";

const required = env.ENDPOINT !== 'local';

export const studentPersonal = new SurveyComponent({
  title: {
    en: 'Student information',
    ar: 'معلومات عن الطالب'
  },
  name: 'student_identification',

  elements:
    [
      {
        title: {
          en: "Student Full Name *",
          ar: "اسم الطالب الرباعي *"
        },
        name: "student_fullname",
        type: "text",
        isRequired: required
      },

      {
        title: {
          en: "Student Picture *",
          ar: "صورة الطالب *"
        },
        name: "student_profile_avatar",
        type: "file_uploader",
        accept: 'image/*,application/pdf',
        isRequired: required
      },

      {
        title: {
          en: "Student Date of Birth *",
          ar: "تاريخ ميلاد الطالب *"
        },
        name: "student_date_of_birth",
        type: "text",
        inputType: "date",
        isRequired: required
      },

      {
        title: {en: "Student Gender *", ar: "جنس الطالب *"},
        name: "student_gender",
        type: "radiogroup",
        isRequired: required,
        choices: [
          {value: 'male', text: {en: "Male", ar: "رجل"}},
          {value: 'female', text: {en: "Female", ar: "إمرأة"}}
        ]
      },

      {
        title: {
          en: "Student Mobile No. * (Please include the country code. Ex. 962791234567)",
          ar: "رقم الهاتف للطالب * (يرجى إدخال رمز البلد. مثال: 961291234567)"
        },
        name: "student_phone",
        type: "phone",
        isRequired: required,
        validators: [
          expressions.phone_en('student_phone'),
          expressions.phone_length('student_phone')
        ]
      },
    ]
})
