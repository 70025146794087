import { SurveyComponent } from "../../../survey_component";

export const IdCardOption = new SurveyComponent({
    value: "id_card",
    text: { en: "National ID Card", ar: "هوية مدنية" }
});

export const MilitaryIdOption = new SurveyComponent({
    value: "military_id",
    text: { en: "Military ID (Certificate of Appointment)", ar: "هوية عسكرية (شهادة التعيين)" }
});

// I really think it is replacement OR lost... ~ Stephan
export const ReplacementOfLostOption = new SurveyComponent({
    value: "replacement_of_lost",
    text: { en: "Replacement of Lost", ar: "بدل فاقد" }
});

export const PassportOption = new SurveyComponent({
    value: "passport",
    text: { en: "Passport", ar: "جواز سفر" }
});

export const SonsOfJordanianWomenOption = new SurveyComponent({
    value: "sons_of_jordanian_women_id",
    text: { en: "National ID Card for the Sons of Jordanian Women", ar: "هوية مدنية ابناء اردنيات" }
});

export const MoiIdOption = new SurveyComponent({
    value: "moi_id",
    text: { en: "Ministry of Interior Card for Syrian", ar: "بطاقة الداخلية للسوريين" }
});

export const TempResidenceGazaStripOption = new SurveyComponent({
    value: "temp_residence_gaza_strip",
    text: { en: "Temporary Residence for People of Gaza Strip", ar: "اقامة مؤقتة ابناء قطاع غزة" }
});

export const ProofOfNationalityOption = new SurveyComponent({
    value: "proof_of_nationality",
    text: { en: "Proof of Nationality", ar: "اثبات جنسية" }
});

export const TravelDocPalestinianRefugeeOption = new SurveyComponent({
    value: "travel_doc_palestinian_refugee",
    text: { en: "Travel Document for Palestinian Refugees", ar: "وثيقة سفر للاجئين الفلسطينيين" }
});