import { SurveyComponent } from "../../../components/survey/survey_component";
import env from "../../../env";

const required = env.ENDPOINT !== "local";

export const OptionalAttachments = new SurveyComponent({
  name: "optional_attachments",
  valueName: "optional_attachments",
  type: "matrixdynamic",
  minRowCount: 0,
  rowCount: 0,
  isRequired: false,
  title: {
    en: "Please upload any other attachments you would like to share with us.",
    ar: "يرجى تحميل أي مرفقات أخرى ترغب في مشاركتها معنا."
  },
  addRowText: {
    en: "Add",
    ar: "إضافة"
  },
  columns: [
    {
      name: "optional_attachment_title",
      isRequired: required,
      cellType: "text",
      inputType: "string",
      title: {
        en: "Title *",
        ar: "العنوان *"
      }
    },
    {
      name: "optional_attachment_file",
      cellType: "file_uploader",
      accept: "image/*,application/pdf",
      isRequired: required,
      title: {
        en: "Attachment *",
        ar: "المرفق *"
      }
    }
  ]
});
