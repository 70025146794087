import { SurveyComponent } from "../../survey_component";
import env from "../../../../env";

const required = env.ENDPOINT !== "local";

/**
 * @param {string} title
 * @param {string} name
 * @returns {SurveyComponent}
 */
const slider = (title, name) =>
  new SurveyComponent({
    title,
    name,
    required,
    type: "nouislider",
    step: 5,
    rangeMin: 0,
    rangeMaxStart: 400,
    maxStep: 400,
    rangeMax: Infinity,
  });

/** @var {SurveyComponent[]} */
const sliders = [
  slider(
    { ar: "رواتب واجور", en: "Salaries and wages" },
    "income_sheet_expenses_salaries_and_wages"
  ),
  slider({ ar: "إيجار", en: "Rent" }, "income_sheet_expenses_rent"),
  slider({ ar: "ماء", en: "Water" }, "income_sheet_expenses_water"),
  slider(
    { ar: "كهرباء", en: "Electricity" },
    "income_sheet_expenses_electricity"
  ),
  slider({ ar: "تدفئة", en: "Heating" }, "income_sheet_expenses_heating"),
  slider(
    { ar: "مواصلات/ وقود", en: "Transportation / Fuel" },
    "income_sheet_expenses_transportation_fuel"
  ),
  slider(
    { ar: "تراخيص/ ضرائب", en: "Licenses / Taxes" },
    "income_sheet_expenses_licenses_taxes"
  ),
  slider({ ar: "تأمين", en: "Insurance" }, "income_sheet_expenses_insurance"),
  slider(
    { ar: "إعلانات", en: "Advertisement" },
    "income_sheet_expenses_advertisement"
  ),
  slider(
    { ar: "لوازم مكتبية", en: "Office Supplies" },
    "income_sheet_expenses_office_supplies"
  ),
  slider(
    { ar: "مصاريف أخرى للمشروع", en: "Other Project Expenses" },
    "income_sheet_expenses_other_project_expenses"
  ),
  slider(
    { ar: "التزامات أخرى للمشروع", en: "Other Project Liabilities" },
    "income_sheet_expenses_other_project_liabilities"
  ),
  slider(
    { ar: "اجمالي المصاريف العائلية", en: "Total Family Expenses" },
    "income_sheet_expenses_total_family_expenses"
  ),
];

export const OperatingAndFamilyExpenses = new SurveyComponent({
  name: "panel_is_oafe",
  type: "panel",
  innerIndent: 1,
  title: {
    ar: "مصاريف تشغيلية وعائلية",
    en: "Operating and Family Expenses",
  },
  elements: [
    ...sliders,
    new SurveyComponent({
      title: { ar: "اجمالي المصاريف", en: "Total Expenses" },
      type: "expression",
      name: "income_sheet_expenses_total",
      expression: `fixed(add(${sliders
        .map((sc) => `{${sc.name}}`)
        .join(", ")}), 0, true)`, // Floating Point Proof
      displayStyle: "currency",
      currency: "JOD",
    }),
  ],
});
