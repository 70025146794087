import { SurveyComponent } from "../../survey_component";
import env from "../../../../env";

const required = env.ENDPOINT !== "local";

export const OtherQuestionRelativeRelationship = new SurveyComponent({
  name: "other_question_relative_relationship",
  type: "text",
  isRequired: required,
  visibleIf: "{other_question_relative_known} == true",
  title: {
    en: "Please specify relationship (ex. uncle) *",
    ar: "يرجى تحديد العلاقة (مثال: خال) *"
  }
});
