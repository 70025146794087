import { SurveyComponent } from "../../survey_component";
import env from "../../../../env";

const required = env.ENDPOINT !== "local";

export const IdentificationNumber = new SurveyComponent({
  name: "id_number",
  type: "text",
  isRequired: required,
  visibleIf: "{id_type} == 'moi_id'",
  title: {
    en: "ID *",
    ar: "الرقم *"
  }
});
