import { SurveyComponent } from "../../../components/survey/survey_component";
import { ProjectOwner } from "../../../components/survey/questions/project_balance_sheet/project_owner";
import { ProjectType } from "../../../components/survey/questions/project_balance_sheet/project_type";
import { ProjectBalanceSheetCurrentAssets } from '../../../components/survey/panels/project_balance_sheet/current_assets';
import { ProjectBalanceSheetAccountsReceivable } from '../../../components/survey/panels/project_balance_sheet/accounts_receivable';
import { ProjectBalanceSheetInventory } from '../../../components/survey/panels/project_balance_sheet/inventory';
import { ProjectBalanceSheetTotalCurrentAssets } from '../../../components/survey/panels/project_balance_sheet/total_current_assets';
import { ProjectBalanceSheetFixedAssets } from '../../../components/survey/panels/project_balance_sheet/fixed_assets';
import { ProjectBalanceSheetTotalAssets } from '../../../components/survey/panels/project_balance_sheet/total_assets';
import { ProjectBalanceSheetCurrentLiabilities } from '../../../components/survey/panels/project_balance_sheet/current_liabilities';
import { ProjectBalanceSheetLongTermLiabilities } from '../../../components/survey/panels/project_balance_sheet/long_term_liabilities';
import { ProjectBalanceSheetTotalLiabilities } from '../../../components/survey/panels/project_balance_sheet/total_liabilities';
import { ProjectBalanceSheetEquity } from '../../../components/survey/panels/project_balance_sheet/equity';
import { ProjectBalanceSheetTotalEquityAndLiabilities } from '../../../components/survey/panels/project_balance_sheet/total_equity_and_liabilities';

// const required = env.ENDPOINT !== "local";

export const ProjectBalanceSheet = new SurveyComponent({
  title: {
    en: 'Balance Sheet',
    ar: "الميزانية العمومية"
  },
  name: 'project_balance_sheet',

  elements: [
    ProjectOwner,
    ProjectType,
    ProjectBalanceSheetCurrentAssets,
    ProjectBalanceSheetAccountsReceivable,
    ProjectBalanceSheetInventory,
    ProjectBalanceSheetTotalCurrentAssets,
    ProjectBalanceSheetFixedAssets,
    ProjectBalanceSheetTotalAssets,
    ProjectBalanceSheetCurrentLiabilities,
    ProjectBalanceSheetLongTermLiabilities,
    ProjectBalanceSheetTotalLiabilities,
    ProjectBalanceSheetEquity,
    ProjectBalanceSheetTotalEquityAndLiabilities
  ]
});
