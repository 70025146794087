import { SurveyComponent } from "../../../components/survey/survey_component";
import {
  MonthlyCashFlowIncome,
  MonthlyCashFlowFamilyExpenses,
} from "../../../components/survey/questions/monthly_cash_flow";

export const MonthlyCashFlow = new SurveyComponent({
  title: {
    en: "Monthly Cash Flow",
    ar: "التدفق النقدي الشهري",
  },
  name: "monthly_cash_flow",
  elements: [MonthlyCashFlowIncome, MonthlyCashFlowFamilyExpenses],
});
