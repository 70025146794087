import { SurveyComponent } from "../survey_component";
import env from "../../../env";

const required = env.ENDPOINT !== "local";

export const DateOfBirth = new SurveyComponent({
  name: "date_of_birth",
  type: "text",
  inputType: "date",
  isRequired: required,
  title: {
    en: "Date of Birth *",
    ar: "تاريخ الميلاد *"
  }
});
