import { SurveyComponent } from "../survey_component";
import {
  GuarantorFirstName,
  GuarantorPhone,
  GuarantorPhoneVerified,
  GuarantorInvitation,
  GuarantorRelation
} from "../questions/guarantor";

export const PanelGuarantorOne = new SurveyComponent({
  name: "panel_guarantor_1",
  type: "panel",
  innerIndent: 1,
  title: {
    en: "Guarantor 1",
    ar: "الكفيل 1",
  },
  elements: [
    GuarantorFirstName,
    GuarantorPhone
      .setVisibleIf("{guarantor_first_name} notempty"),
    GuarantorPhoneVerified
      .setVisibleIf("{guarantor_first_name} notempty"),
    GuarantorInvitation
      .setVisibleIf("{guarantor_first_name} notempty"),
    GuarantorRelation
      .setVisibleIf("{guarantor_first_name} notempty"),
  ],
});
