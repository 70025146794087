const withFirstValuePresent = (callback) => (params) =>
  params[0] ? callback(params[0]) : true;

export const register_validators = (survey, allowedCountries) => {
  survey.FunctionFactory.Instance.register(
    "phoneEn",
    withFirstValuePresent((v) => !!v.toString().match(new RegExp("^[0-9]*$")))
  );

  survey.FunctionFactory.Instance.register(
    "phoneLength",
    withFirstValuePresent((v) =>
      allowedCountries.some(
        (country) =>
          v.toString().startsWith(country.country_code) &&
          v.toString().length ===
            country.country_code.length + country.country_phone_length
      )
    )
  );
};

export function register_differentPhoneNumbers(Survey) {
  Survey.FunctionFactory.Instance.register(
    "differentPhoneNumbers",
    ([data, field]) => {
      if (!data || !Array.isArray(data) || !field) {
        return;
      }
      var phone_numbers = data.map((f) => f[field]);
      return new Set(phone_numbers).size === phone_numbers.length;
    }
  );
}

export function register_samePhoneNumbersMatrix(Survey) {
  // This function is similar to same_phone_number but, it compares the phone number
  // of the applicant to another field, but this function compares fields in a jsonfield
  Survey.FunctionFactory.Instance.register(
    "samePhoneNumbersMatrix",
    ([data, phone, field]) => {
      if (!data || !Array.isArray(data) || !field) {
        return;
      }
      let phone_numbers = data.map((f) => f[field]);
      return !phone_numbers.includes(phone.toString())
    }
  );
}

export function register_hasValue(Survey) {
  Survey.FunctionFactory.Instance.register("hasValue", (params) => {
    let fieldList = params[0];
    let comparedValue = params[1];
    
    for (let item of fieldList) {
      if (item === comparedValue) {
        return true
      }
    }

    return false;
  });
}

export const regexValidators = {
  max_two_decimal_places() {
    return {
      type: "regex",
      regex: "^[0-9]+(\\.[0-9]{0,2})?$",
      text: {
        en: "Please enter a number with no more than 2 decimal places.",
        rw: "Nyamuneka andika umubare utarenze 2 icumi",
        rn: "This will be in kirundi: No more than 2 decimals",
        "fr-TD":
            "Veuillez mettre chiffre avec moins de deux decimaux",
        "fr-BI":
            "Veuillez mettre chiffre avec moins de deux decimaux",
      }
    };
  },
  max_three_decimal_places() {
    return {
      type: "regex",
      regex: "^[0-9]+(\\.[0-9]{0,3})?$",
      text: {
        en: "Please enter a number with no more than 3 decimal places.",
        rw: "This will be in kirundi: No more than 3 decimals",
        rn: "This will be in kirundi: No more than 3 decimals",
        "fr-TD":
            "Veuillez mettre chiffre avec moins de trois decimaux",
        "fr-BI":
            "Veuillez mettre chiffre avec moins de trois decimaux",
      }
    };
  },
};

export const expressions = {
  phone_en(field) {
    return {
      type: "expression",
      text: {
        en: "Phone number must consist of Western numerals (0-9)",
        ar: "يجب كتابة رقم الهاتف بالارقام الانجليزية كما في المثال (0-9)",
        rw: "Imibare ya telefone igomba kuba igizwe nimibare(0-9)",
        rn: "Imibare ya telefone igomba kuba igizwe nimibare(0-9)", // Requires correct translation for BLL
        "es-GT":
          "El número de teléfono debe constar de números occidentales (0-9)",
        "es-MX":
          "El número de teléfono debe constar de números occidentales (0-9)",
        "es-HN":
          "El número de teléfono debe constar de números occidentales (0-9)",
        "fr-BI":
          "Le numéro de téléphone doit être composé de chiffres occidentaux (0-9)",
        "fr-CD":
          "Le numéro de téléphone doit être composé de chiffres occidentaux (0-9)",
        "fr-TD":
          "Le numéro de téléphone doit être composé de chiffres occidentaux (0-9)",
      },
      expression: `phoneEn({${field}}) = true`, // Floating Point Proof
    };
  },
  phone_length(field) {
    return {
      type: "expression",
      text: {
        en: "Phone number digits must be correct",
        ar: "عدد الأرقام في رقم الهاتف يجب أن يكون صحيح",
        rw: "Imibare ya telefone ikwiye kuba ariyo kandi yuzuye",
        rn: "Imibare ya telefone ikwiye kuba ariyo kandi yuzuye", // Requires correct translation for BLL
        "es-GT": "Los dígitos del número de teléfono deben ser correctos",
        "es-MX": "El número de teléfono debe de constar de 10 dígitos",
        "es-HN": "Los dígitos del número de teléfono deben ser correctos",
        "fr-BI": "Les chiffres du numéro de téléphone doivent être corrects",
        "fr-CD": "Les chiffres du numéro de téléphone doivent être corrects",
        "fr-TD": "Les chiffres du numéro de téléphone doivent être corrects",
      },
      expression: `phoneLength({${field}}) = true`, // Floating Point Proof
    };
  },
  same_phone_number(field) {
    // This function compares the phone number of the applicant to a field that
    // is not in a jsonfield. See samePhoneNumbersMatrix above.
    return {
      type: "expression",
      text: {
        en: "Please make sure this number is not the same as your number",
        ar: "يرجى التأكد من أن هذا الرقم ليس هو نفسه رقمك",
        rw: "Iyi nimero igomba kuba idahuye niyawe",
        rn: "Iyi nimero itegerezwa kuba idahuye niyawe",
        "es-GT":
          "Asegúrese de que este número no sea el mismo que el suyo",
        "es-MX":
          "Asegúrese de que este número no sea el mismo que el suyo",
        "es-HN":
          "Número ingresado no es permitido, favor ingresar número móvil diferente al solicitante.",
        "fr-TD":
          "Veuillez vous assurer que ce numéro n'est pas le même que votre numéro",
        "fr-BI":
          "Veuillez vous assurer que ce numéro n'est pas le même que votre numéro",
      },
      expression: `{${field}} != {phone_number} and {${field}} != {loan_phone}`, // Comparing intake.phone_number and loan.phone
    };
  },
  compare_phone_numbers(field_1, field_2, name_of_field_1, name_of_field_2) {
    return {
      type: "expression",
      text: {
        en: `Please make sure ${name_of_field_1} and ${name_of_field_2} are not equal`,
        ar: "يرجى التأكد من أن هذا الرقم ليس هو نفسه رقمك", // @Todo needs translation
        rw: "Iyi nimero igomba kuba idahuye niyawe", // @Todo needs translation
        rn: "Iyi nimero itegerezwa kuba idahuye niyawe", // @Todo needs translation
        "es-GT":
          `Asegúrese de que ${name_of_field_1} y ${name_of_field_2} no sean iguales`,
        "es-MX":
          `Asegúrese de que ${name_of_field_1} y ${name_of_field_2} no sean iguales`,
        "es-HN":
          `Asegúrese de que ${name_of_field_1} y ${name_of_field_2} no sean iguales`,
        "fr-TD":
          `Veuillez vous assurer que ${name_of_field_1} et ${name_of_field_2} ne sont pas identiques`,
        "fr-BI":
          `Veuillez vous assurer que ${name_of_field_1} et ${name_of_field_2} ne sont pas identiques`,
      },
      expression: `{${field_1}} != {${field_2}}`,
    };
  },
  amount_greater_than_zero(field) {
    return {
      type: "numeric",
      minValue: 1,
      // maxValue: 10000, // What (if at all) maxValue do we want? Should this be configurable per client?
      text: {
        en: "Please make sure the amount is greater than 0",
        ar: "Please make sure the amount is greater than 0", // @Todo needs translation
        "es-MX": "Asegúrate de que el monto sea mayor a 0",
        "es-HN": "Asegúrate de que el monto sea mayor a 0"
      },
    };
  },
  amount_equal_or_greater_than_zero(field) {
    return {
      type: "numeric",
      minValue: 0,
      text: {
        en: "Please make sure the amount is equal or greater than 0",
        ar: "Please make sure the amount is equal or greater than 0", // @Todo needs translation
        "es-MX": "Asegúrate de que el monto sea igual o mayor a 0",
        "es-HN": "Asegúrate de que el monto sea igual o mayor a 0",
        "fr-TD": "Veuillez vous assurer que le montant est égal ou supérieur à 0"
      },
    };
  },
  amount_in_range(field_1, field_2) {
    return {
      type: "numeric",
      minValue: field_1,
      maxValue: field_2,
      text: {
        en: `Please enter an amount between ${field_1} and ${field_2}`,
        ar: `أدخل مبلغًا يتراوح بين ${field_1} و${field_2}`,
        rw: `Andika umubare w'amafaranga hagati ya ${field_1} na ${field_2}`
      },
    };
  },
  curp() {
    return {
      type: "regexvalidator",
      text: {
        en: `The CURP consists of 18 characters in the following order:
          4 letters and 6 digits followed by 6 letters and 2 digits or
          7 letters and 1 digit`,
        "es-MX": `La CURP consiste de 18 caracteres en el siguiente orden:
          4 letras y 6 dígitos seguidos de 6 letras y 2 dígitos o
          7 letras y 1 dígito`,
      },
      regex: `^[A-Za-z]{4}[0-9]{6}([A-Za-z]{6}[0-9]{2}|[A-Za-z]{7}[0-9]{1})$`,
    }
  }
};
