import { SurveyComponent } from "../../../components/survey/survey_component";
import { DateOfBirth } from "../../../components/survey/questions/date_of_birth";
import env from "../../../env";

const required = env.ENDPOINT !== 'local'

export const personal = new SurveyComponent({
  title: {
    en: 'Applicant Information', ar:
      "معلومات مقدّم الطلب"
  },
  name: 'personal',

  elements:
    [
      {
        title: {en: "Applicant Full Name *", ar: "اسم مقدّم الطلب الرباعي *"},
        name: "last_name",
        type: "text",
        isRequired: required,
      },
      {
        title: {en: "Can you share a picture for yourself? *", ar: "صورة مقدّم الطلب؟ *"},
        name: "profile_avatar",
        type: "file_uploader",
        accept: 'image/*,application/pdf',
        isRequired: required
      },

      DateOfBirth,

      {
        title: {en: "What is your gender? *", ar: "ما هو جنسك؟ *"},
        name: "gender",
        type: "radiogroup",
        isRequired: required,
        choices: [
          {value: 'male', text: {en: "Male", ar: "رجل"}},
          {value: 'female', text: {en: "Female", ar: "امرأة"}}
        ]
      },

      {
        title: {en: "Last Educational Level *", ar: "آخر مستوى تعليمي *"},
        name: "education",
        type: "dropdown",
        isRequired: required,
        choices: [
          {value: 'unschooled', text: {en: "Unschooled", ar: "غير متعلم"}},
          {value: 'primary_school', text: {en: "Primary school", ar: "مدرسة ابتدائية"}},
          {value: 'secondary_school', text: {en: "Secondary education", ar: "تعليم ثانوي"}},
          {value: 'bachelor_diploma', text: {en: "Bachelor diploma", ar: "شهادة بكالوريوس"}},
          {value: 'master_diploma', text: {en: "Master diploma or higher", ar: "شهادة ماجستير أو أعلى"}},
        ]
      },

      {
        title: {en: "Major of Study *", ar: "تخصص الدراسة *"},
        name: "education_field",
        type: "dropdown",
        isRequired: required,
        visibleIf: "{education} contains 'diploma'",
        choices: [
          {value: 'arts', text: {en: "Humanities and arts", ar: "العلوم الإنسانية والفنون"}},
          {value: 'social', text: {en: "Social sciences", ar: "العلوم الإجتماعية"}},
          {value: 'business', text: {en: "Business/law", ar: "القانون/الأعمال"}},
          {value: 'science', text: {en: "Science/math", ar: "العلوم/الرياضيات"}},
          {value: 'engineering', text: {en: "Engineering", ar: "الهندسة"}},
          {value: 'agriculture', text: {en: "Agriculture", ar: "الزراعة"}},
          {value: 'health', text: {en: "Health and welfare", ar: "الصحة والخدمات الإجتماعية"}},
          {value: 'other_diploma', text: {en: "Other degree", ar: " أخرى"}},
        ]
      },
    ]
});
