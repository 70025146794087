import { SurveyComponent } from "../../survey_component";
import env from "../../../../env";

const required = env.ENDPOINT !== "local";

export const HouseHasPersonalComputer = new SurveyComponent({
  name: "house_has_personal_computer",
  type: "radiogroup",
  isRequired: required,
  title: {
    en: "Personal computer *",
    ar: "كمبيوتر شخصي *"
  },
  choices: [
    {
      value: true,
      text: {
        en: "Yes",
        ar: "نعم"
      }
    },
    {
      value: false,
      text: {
        en: "No",
        ar: "لا"
      }
    }
  ]
});
